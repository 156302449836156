import React from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

const TextFieldDate = (props) => {
    const { onChange, value, label, name } = props;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                onChange={onChange}
                value={value}
                label={label}
                format="dd/MM/yyyy"
                name={name}
            />
        </LocalizationProvider>
    );
}

export default TextFieldDate;