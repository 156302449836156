import {
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { coreografiaTipoText } from "./CoreografiaTipo";
import Button from "../UI/Button";
import { Link } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";


const CoreografiaView = ({
  coreografia,
  handleAprovarDesaprovarSeletiva,
  isLoadingAction,
}) => {

  return (
    <Grid container sx={{ mt: 2 }} spacing={3}>

      <Grid item xs={6}>
        <Typography variant='body1'>
          <span style={{ fontWeight: 'bold' }}>
            Coreografia:{" "}
          </span>
          {coreografia.descricao ? coreografia.descricao : ''}
        </Typography>

      </Grid >
      <Grid item xs={4}>
        <Typography variant='body1'>
          <span style={{ fontWeight: 'bold' }}>
            Tempo Máximo:{" "}
          </span>
          {coreografia.tempoMaximo ? coreografia.tempoMaximo + " min" : ""}
        </Typography>

      </Grid >
      <Grid item xs={4}>
        <Typography variant='body1'>
          <span style={{ fontWeight: 'bold' }}>
            Nome Música:{" "}
          </span>
          {coreografia.nomeMusica ? coreografia.nomeMusica : ""}
        </Typography>

      </Grid >
      <Grid item xs={4}>
        <Typography variant='body1'>
          <span style={{ fontWeight: 'bold' }}>
            Nome Compositor:{" "}
          </span>
          {coreografia.nomeCompositor ? coreografia.nomeCompositor : ""}
        </Typography>

      </Grid >
      <Grid item xs={12}>
        {coreografia.urlArquivoMusica !== null &&
          coreografia.urlArquivoMusica !== "" ? (
          <>
            <Button
              component={Link}
              to={coreografia.urlArquivoMusica}
              target="_blank"
              color='secondary'
              download
            >
              <DownloadIcon sx={{ mr: 1 }} />
              Download Música
            </Button>
          </>
        ) : ''}
      </Grid>

      <Grid item xs={4}>
        <Typography variant='body1'>
          <span style={{ fontWeight: 'bold' }}>
            Tipo:{" "}
          </span>
          {
            coreografia && coreografia.coreografiaTipo
              ? coreografiaTipoText(coreografia.coreografiaTipo)
              : ''
          }
        </Typography>
      </Grid >
      <Grid item xs={12}>
        <Typography variant='body1'>
          <span style={{ fontWeight: 'bold' }}>
            Resumo Coreografia:{" "}
          </span>
          {coreografia.resumoCoreografia ? coreografia.resumoCoreografia : ""}
        </Typography>
      </Grid >
      <Grid item xs={12}>
        <Typography variant='body1'>
          <span style={{ fontWeight: 'bold' }}>
            Informações Adicionais:{" "}
          </span>
          {coreografia.informacoesAdicionais
            ? coreografia.informacoesAdicionais
            : ""}
        </Typography>
      </Grid >





      {
        coreografia.eventoDataCategoria.eventoData.evento.seletiva &&
        <>
          <Grid item xs={12}>
            <Typography variant='body1'>
              <span style={{ fontWeight: 'bold' }}>
                Url Vídeo Seletiva:{" "}
              </span>

              {coreografia.urlVideoSeletiva
                ?
                <>
                  <Link
                    style={{
                      textDecoration: 'underline',
                      color: 'blue'
                    }}
                    to={coreografia.urlVideoSeletiva}
                    target="_blank"
                    rel="noopener noreferrer"
                  > {coreografia.urlVideoSeletiva}</Link>
                </>
                : ""}
            </Typography>
          </Grid >
          <Grid item xs={12}>
            <Typography variant='body1'>
              <span style={{ fontWeight: 'bold' }}>
                Seletiva Aprovada:{" "}
              </span>
              {coreografia.aprovadoSeletiva
                ? "Sim"
                : "Não"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              color='secondary'
              isLoadingAction={isLoadingAction}
              onClick={handleAprovarDesaprovarSeletiva}
            >
              {coreografia.aprovadoSeletiva
                ? "Desaprovar "
                : "Aprovar "}
              Seletiva
            </Button>
          </Grid>
        </>
      }


    </Grid >
  );
};

export default CoreografiaView;
