import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "../UI/Button";
import Loading from "../UI/Loading";
import Title from "../UI/Title";

const GeneroTable = ({
  listGeneros,
  handleDelete,
  isLoadingRemove,
  setGenero,
  handleChangePage,
  isLoading,
  size,
  handleChangeRowsPerPage,
}) => {
  const handleNovoRegistro = () => {
    setGenero({
      eNovo: true,
    });
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Title>Gênero</Title>
      </Grid>
      <Grid item sx={3}>
        <Button onClick={handleNovoRegistro}>Novo Registro</Button>
      </Grid>
      <TablePagination
        component="div"
        count={listGeneros.totalElements}
        page={listGeneros.number ? listGeneros.number : 0}
        onPageChange={handleChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Descrição</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <Loading description="Carregando gêneros..." />
          ) : (
            <>
              {listGeneros.content.length === 0 ? (
                <Typography sx={{ py: 3 }}>Nenhum gênero cadastrado</Typography>
              ) : (
                <>
                  {listGeneros.content.map((genero) => (
                    <TableRow>
                      <TableCell>
                        {genero.descricao ? genero.descricao : ""}
                      </TableCell>
                      <TableCell align="right">
                        {isLoadingRemove ? (
                          <CircularProgress
                            sx={{ mr: 5 }}
                            color="secondary"
                            size={20}
                          />
                        ) : (
                          <DeleteIcon
                            sx={{ mr: 5 }}
                            style={{ cursor: "pointer" }}
                            fontSize="small"
                            onClick={() => {
                              handleDelete(genero.codigo);
                            }}
                            color="secondary"
                          />
                        )}
                        <Button
                          variant="outlined"
                          size="small"
                          color="secondary"
                          onClick={() => setGenero(genero)}
                        >
                          Selecionar
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={listGeneros.totalElements ? listGeneros.totalElements : 0}
        page={listGeneros.number ? listGeneros.number : 0}
        onPageChange={handleChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
    </React.Fragment>
  );
};

export default GeneroTable;
