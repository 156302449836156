import { SERVER_URL } from '../../constants';
import { fetchGetAuth, fetchPostAuth, fetchPostAuthFile, fetchRemoveAuthReturn } from '../../util/FetchUtil';

const controller = 'midia';

export const page = (pageNumber, size) => {
  const url = SERVER_URL + controller + '/query-page?page=' + pageNumber + '&size=' + size;
  return fetchGetAuth(url);
}

export const remove = (codigo) => {
  const url = SERVER_URL + controller + "/" + codigo;
  return fetchRemoveAuthReturn(url);
}

export const saveMidia = (categoria) => {
  const url = SERVER_URL + controller + "/save";
  return fetchPostAuth(url, categoria);
}

export const removeUploadedMidia = (codigo) => {
  const url = SERVER_URL + controller + "/remove-uploaded-midia/" + codigo;
  return fetchRemoveAuthReturn(url);
}

export const uploadMidia = (formData, codMidia) => {
  const url = SERVER_URL + controller + '/upload-midia/' + codMidia;
  return fetchPostAuthFile(url, formData);
}