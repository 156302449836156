import {
  Box,
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/system";
import Button from "../UI/Button";
import {
  ASSISTENTE,
  BAILARINO,
  COREOGRAFO,
  DIRETOR,
  participantePerfilEnumText,
} from "./ParticipantePerfilEnum";
import { errorMessage } from "../util/ConfirmAlertUtil";
import {
  findByParticipanteNome,
  findByParticipantePerfilEnum,
} from "./api-participante-perfil";
import ParticipantePerfilTableSelectView from "./ParticipantePerfilTableSelectView";

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const ParticipantePerfilFormSearch = ({
  setParticipantePerfil,
  openDialog,
  handleCloseDialog,
}) => {
  const nomeKey = "nome";
  const perfilKey = "perfil";
  const [procurar, setProcurar] = useState({
    type: nomeKey,
    value: "",
  });
  const [listParticipantePerfis, setListParticipantePerfis] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });

  const [isLoading, setIsLoading] = useState(false);

  const [size, setSize] = useState(10);

  const handleParticipantePerfilChangePage = (newPage) => {
    fetchParticipantePerfil(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
  };

  const fetchParticipantePerfil = (newPage) => {
    if (procurar.type === nomeKey) {
      findByParticipanteNome(procurar.value, newPage, size).then((data) => {
        setIsLoading(true);
        if (data) {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setListParticipantePerfis(data);
          }
        }
        setIsLoading(false);
      });
    } else {
      findByParticipantePerfilEnum(procurar.value, newPage, size).then(
        (data) => {
          if (data) {
            setIsLoading(true);
            if (data.error) {
              errorMessage(data.message);
            } else {
              setListParticipantePerfis(data);
            }
          }
          setIsLoading(false);
        }
      );
    }
  };

  const handleOnChange = (event) => {
    setProcurar((prevState) => ({
      ...prevState,
      value: event.target.value,
    }));
  };

  const handleOnChangeType = (event) => {
    setProcurar((prevState) => ({
      ...prevState,
      type: event.target.value,
    }));
  };

  return (
    <Dialog onClose={handleCloseDialog} open={openDialog}>
      <Grid container spacing={3} sx={{ p: 3 }}>
        <Grid item xs={12}>
          <FormControl>
            <FormLabel id="searchByLabel">Procurar Por</FormLabel>
            <RadioGroup
              row
              aria-labelledby="searchByLabel"
              defaultValue={nomeKey}
              name="radio-buttons-search-by"
              value={procurar.type}
              onChange={handleOnChangeType}
            >
              <FormControlLabel
                value={nomeKey}
                control={<Radio />}
                label="Nome"
              />
              <FormControlLabel
                value={perfilKey}
                control={<Radio />}
                label="Perfil"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {procurar.type === nomeKey ? (
          <Grid item xs={12}>
            <FormGrid item xs={10}>
              <FormLabel htmlFor="nome" required>
                Nome
              </FormLabel>
              <OutlinedInput
                id="nome"
                name="nome"
                type="name"
                value={procurar.value ? procurar.value : ""}
                onChange={handleOnChange}
                placeholder="Nome"
                required
              />
            </FormGrid>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="perfilLabel">Perfil</InputLabel>
                <Select
                  labelId="perfilLabel"
                  id="perfil"
                  value={
                    procurar.type === perfilKey && procurar.value
                      ? procurar.value
                      : null
                  }
                  label="Perfil"
                  onChange={handleOnChange}
                  name="type"
                >
                  <MenuItem value={ASSISTENTE}>
                    {participantePerfilEnumText(ASSISTENTE)}
                  </MenuItem>
                  <MenuItem value={BAILARINO}>
                    {participantePerfilEnumText(BAILARINO)}
                  </MenuItem>
                  <MenuItem value={COREOGRAFO}>
                    {participantePerfilEnumText(COREOGRAFO)}
                  </MenuItem>
                  <MenuItem value={DIRETOR}>
                    {participantePerfilEnumText(DIRETOR)}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        )}

        <FormGrid item xs={12}>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Button onClick={() => fetchParticipantePerfil(0)}>
                Procurar
              </Button>
            </Grid>
          </Grid>
        </FormGrid>
        <Grid item xs={12}>
          <ParticipantePerfilTableSelectView
            listParticipantePerfis={listParticipantePerfis}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            size={size}
            handleParticipantePerfilChangePage={
              handleParticipantePerfilChangePage
            }
            setParticipantePerfil={setParticipantePerfil}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ParticipantePerfilFormSearch;
