import { FormLabel, Grid, OutlinedInput } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import ButtonSave from "../UI/ButtonSave";

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const CredentialK8PayForm = ({
  credentialK8Pay,
  setCredentialK8Pay,
  handleSave,
}) => {
  const handleOnChange = (event) => {
    setCredentialK8Pay((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormGrid item xs={4}>
          <FormLabel htmlFor="clientId" required>
            ClientId
          </FormLabel>
          <OutlinedInput
            id="clientId"
            name="clientId"
            type="name"
            value={
              credentialK8Pay && credentialK8Pay.clientId
                ? credentialK8Pay.clientId
                : ""
            }
            onChange={handleOnChange}
            placeholder="ClientId"
            required
          />
        </FormGrid>
      </Grid>
      <Grid item xs={12}>
        <FormGrid item xs={4}>
          <FormLabel htmlFor="clientSecret" required>
            ClientSecret
          </FormLabel>
          <OutlinedInput
            id="clientSecret"
            name="clientSecret"
            type="name"
            value={
              credentialK8Pay && credentialK8Pay.clientSecret
                ? credentialK8Pay.clientSecret
                : ""
            }
            onChange={handleOnChange}
            placeholder="ClientSecret"
            required
          />
        </FormGrid>
      </Grid>

      <Grid item xs={12}>
        <FormGrid item xs={4}>
          <FormLabel htmlFor="chaveAES" required>
            ChaveAES
          </FormLabel>
          <OutlinedInput
            id="chaveAES"
            name="chaveAES"
            type="name"
            value={
              credentialK8Pay && credentialK8Pay.chaveAES
                ? credentialK8Pay.chaveAES
                : ""
            }
            onChange={handleOnChange}
            placeholder="ClientSecret"
            required
          />
        </FormGrid>
      </Grid>

      <FormGrid item xs={12}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <ButtonSave handleSave={handleSave} />
          </Grid>
        </Grid>
      </FormGrid>
    </Grid>
  );
};

export default CredentialK8PayForm;
