import { Grid, Paper } from "@mui/material";
import React, { useEffect } from "react";
import WelcomeUser from "./WelcomeUser";
import { errorMessage } from "./util/ConfirmAlertUtil";
import { useDispatch } from "react-redux";
import { currentUser } from "./Usuario/api-usuario";
import { setUsuario } from "./Usuario/actions/actions";
import GuideFirstSteps from "./GuideFirstSteps";

const Home = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    currentUser().then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          dispatch(setUsuario(data));
        }
      }
    });
  }, [dispatch]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <WelcomeUser />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <GuideFirstSteps />
        </Paper>
      </Grid>
      {/* <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <UltimosEventosTable />
        </Paper>
      </Grid> */}
    </Grid>
  );
};

export default Home;
