import {
  Divider,
  Grid,
  TablePagination,
  Typography,
} from "@mui/material";
import React from "react";
import Loading from "../UI/Loading";
import Title from "../UI/Title";
import { dateFormat } from "../../util/DateUtil";
import { currencyRound } from "../../util/DoubleUtil";

const CoreografiaPagamentoTableView = ({
  listCoreografiaPagamentos,
  handleChangePage,
  isLoading,
  size,
  handleChangeRowsPerPage,
}) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Title>Coreografia Pagamento</Title>
      </Grid>
      <TablePagination
        component="div"
        count={listCoreografiaPagamentos.totalElements}
        page={
          listCoreografiaPagamentos.number
            ? listCoreografiaPagamentos.number
            : 0
        }
        onPageChange={handleChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
      {isLoading ? (
        <Loading description="Carregando pagamentos..." />
      ) : (
        <>
          {listCoreografiaPagamentos.content.length === 0 ? (
            <Typography sx={{ py: 3 }}>
              Nenhum pagamento cadastrado
            </Typography>
          ) : (
            <>
              {listCoreografiaPagamentos.content.map(
                (coreografiaPagamento) => (
                  <Grid container direction='row' spacing={3}>
                    <Grid item xs={12}>
                      <p>
                        <span style={{ fontWeight: 'bold' }}>Descrição: </span>
                        {coreografiaPagamento.descricao
                          ? coreografiaPagamento.descricao
                          : ""}
                      </p>
                    </Grid>
                    <Grid item xs={3}>
                      <p>
                        <span style={{ fontWeight: 'bold' }}>Data Emissão: </span>
                        {coreografiaPagamento.dataEmissao
                          ? dateFormat(coreografiaPagamento.dataEmissao)
                          : ""}
                      </p>
                    </Grid>
                    <Grid item xs={3}>
                      <p>
                        <span style={{ fontWeight: 'bold' }}>Valor: </span>
                        {coreografiaPagamento.valor
                          ? currencyRound(coreografiaPagamento.valor)
                          : ""}
                      </p>
                    </Grid>
                    <Grid item xs={3}>
                      <p>
                        <span style={{ fontWeight: 'bold' }}>Data Pagamento: </span>
                        {coreografiaPagamento.dataPagamento
                          ? dateFormat(coreografiaPagamento.dataPagamento)
                          : ""}
                      </p>
                    </Grid>
                    <Grid item xs={3}>
                      <p>
                        <span style={{ fontWeight: 'bold' }}>Valor Recebido: </span>
                        {coreografiaPagamento.valorRecebido
                          ? currencyRound(coreografiaPagamento.valorRecebido)
                          : ""}
                      </p>
                    </Grid>
                    <Grid item xs={3}>
                      <p>
                        <span style={{ fontWeight: 'bold' }}>Data Limite Desconto: </span>
                        {coreografiaPagamento.dataLimiteDesconto
                          ? dateFormat(coreografiaPagamento.dataLimiteDesconto)
                          : ""}
                      </p>
                    </Grid>
                    <Grid item xs={3}>
                      <p>
                        <span style={{ fontWeight: 'bold' }}>Porcentagem Desconto: </span>
                        {coreografiaPagamento.porcentagemDesconto
                          ? currencyRound(coreografiaPagamento.porcentagemDesconto) + ' %'
                          : ""}
                      </p>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                  </Grid>
                )
              )}
            </>
          )}
        </>
      )}
      <TablePagination
        component="div"
        count={
          listCoreografiaPagamentos.totalElements
            ? listCoreografiaPagamentos.totalElements
            : 0
        }
        page={
          listCoreografiaPagamentos.number
            ? listCoreografiaPagamentos.number
            : 0
        }
        onPageChange={handleChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
    </React.Fragment>
  );
};

export default CoreografiaPagamentoTableView;
