import React, { useEffect, useState } from "react";
import { errorMessage } from "../util/ConfirmAlertUtil";
import { toast } from "react-toastify";
import { Box, FormLabel, Grid, styled } from "@mui/material";
import Title from "../UI/Title";
import { useDispatch, useSelector } from "react-redux";
import Button from "../UI/Button";
import InputImageByte from "../UI/InputImageByte";
import { removeUploadedLogo, uploadLogo } from "./api-agencia-evento";
import { confirmAlert } from "react-confirm-alert";
import { setUsuario } from "../Usuario/actions/actions";


const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const AgenciaEventoConfContainer = () => {
  const usuario = useSelector(state => state.user);
  const [agenciaEvento, setAgenciaEvento] = useState(null);

  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (usuario.agenciaEvento) {
      setAgenciaEvento(usuario.agenciaEvento);
    }

  }, [usuario]);

  useEffect(() => {
    if (file) {
      // create the preview
      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);

      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [file]);

  const handleUploadLogo = (formData) => {
    setIsLoadingAction(true);
    uploadLogo(formData).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setAgenciaEvento(data);
          dispatch(setUsuario({
            ...usuario,
            agenciaEvento: data
          }));
          toast.success("Operação Realizada com Sucesso.");
        }
      }

      setIsLoadingAction(false);
    });
  };

  const handleRemoveUploadedLogo = () => {
    confirmAlert({
      title: "Alerta",
      message: "Deseja deletar este logo?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            removeUploadedLogo().then((data) => {
              if (data) {
                if (data.error) {
                  errorMessage(data.message);
                } else {
                  setAgenciaEvento(data);
                  dispatch(setUsuario({
                    ...usuario,
                    agenciaEvento: data
                  }));
                  toast.success("Operação Realizada com Sucesso.");
                }
              }
            });
          },
        },
        {
          label: "Não",
          onClick: () => { },
        },
      ],
    });
  };

  const handleChangeImage = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUploadLogoInit = () => {
    if (file === null) {
      return;
    }

    const fileFormData = new FormData();
    fileFormData.append("file", file);
    handleUploadLogo(fileFormData);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Title>Configurações Agência Evento</Title>
      </Grid>

      <Grid item xs={12}>
        <Box component="section" sx={{ p: 2, border: "1px dashed grey" }}>
          <FormGrid item xs={4}>
            <FormLabel>Logo</FormLabel>
            {agenciaEvento !== null && agenciaEvento.urlLogo !== null &&
              agenciaEvento.urlLogo !== "" ? (
              <>
                <img
                  style={{
                    width: "200px",
                  }}
                  src={agenciaEvento.urlLogo}
                  alt=""
                />
                <Button
                  isLoadingAction={isLoadingAction}
                  sx={{ mt: 2 }} onClick={handleRemoveUploadedLogo}>
                  Remover Logo
                </Button>
              </>
            ) : (
              <>
                <FormLabel htmlFor="documentFile">
                  Selecione o Logo
                </FormLabel>

                <InputImageByte
                  id="documentFile"
                  name="documentFile"
                  image={preview}
                  handleChangeImage={handleChangeImage}
                  multiple={false}
                />
                {file && (
                  <Button
                    sx={{ mt: 2 }}
                    disabled={isLoadingAction}
                    onClick={handleUploadLogoInit}
                  >
                    Upload Logo
                  </Button>
                )}
              </>
            )}
          </FormGrid>
        </Box>
      </Grid>

    </Grid>
  );
};

export default AgenciaEventoConfContainer;
