import {
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import Loading from "../UI/Loading";
import Title from "../UI/Title";

const CoreografiaNotaAudioTableView = ({
  listCoreografiaNotaListAudio,
  isLoading,
}) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Title>Coreografia Nota Jurado</Title>
      </Grid>

      {isLoading ? (
        <Loading description="Carregando notas..." />
      ) : (
        <>
          {listCoreografiaNotaListAudio.length === 0 ? (
            <Typography sx={{ py: 3 }}>
              Nenhuma nota cadastrada
            </Typography>
          ) : (
            <>
              {listCoreografiaNotaListAudio.map(
                (coreografiaNotaListAudio) => (
                  <Grid container direction='row' spacing={3}>
                    <Grid item xs={12}>
                      <p>
                        <span style={{ fontWeight: 'bold' }}>Jurado: </span>
                        {coreografiaNotaListAudio.coreografiaNota
                          ? coreografiaNotaListAudio.coreografiaNota.usuario.nome
                          : ""}
                      </p>
                    </Grid>
                    <Grid item xs={3}>
                      <p>
                        <span style={{ fontWeight: 'bold' }}>Nota: </span>
                        {coreografiaNotaListAudio.coreografiaNota
                          ? coreografiaNotaListAudio.coreografiaNota.nota
                          : ""}
                      </p>
                    </Grid>
                    {coreografiaNotaListAudio.listaCNA.map((cna) => (
                      <>
                        <Grid item xs={12}>
                          <audio src={cna.blobAudio} controls={true} />
                        </Grid>
                      </>
                    ))}

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                  </Grid>
                )
              )}
            </>
          )}
        </>
      )}

    </React.Fragment>
  );
};

export default CoreografiaNotaAudioTableView;
