import React from "react";
import Button from "./Button";
import { Save } from "@mui/icons-material";

const ButtonSave = (props) => {
  return (
    <Button
      isLoadingAction={props.isLoadingAction}
      onClick={props.handleSave}
      {...props}
    >
      <Save sx={{ mr: 2 }} />
      Salvar
    </Button>
  );
};

export default ButtonSave;
