import { SERVER_URL } from '../../constants';
import { fetchGet, fetchGetAuth, fetchPostAuth, fetchRemoveAuthReturn } from '../../util/FetchUtil';

const controller = 'evento';

export const page = (pageNumber, size) => {
  const url = SERVER_URL + controller + '/query-page?page=' + pageNumber + '&size=' + size;
  return fetchGetAuth(url);
}

export const pagePublicPermissaoDivulgacaoData = (pageNumber, size) => {
  const url = SERVER_URL + controller + '/public/query-page?page=' + pageNumber + '&size=' + size + '&permissao-divulgacao-data-evento=true';
  return fetchGet(url);
}

export const remove = (codigo) => {
  const url = SERVER_URL + controller + "/" + codigo;
  return fetchRemoveAuthReturn(url);
}

export const saveEvento = (categoria) => {
  const url = SERVER_URL + controller + "/save";
  return fetchPostAuth(url, categoria);
}

export const findByDataEventoGreaterThanEqualNow = (pageNumber, size) => {
  const url = SERVER_URL + controller + '/query-page?type=data-evento-greater-than-equal-now&page=' + pageNumber + '&size=' + size;
  return fetchGetAuth(url);
}
export const findAllByOrderByDataEventoDesc = (pageNumber, size) => {
  const url = SERVER_URL + controller + '/query-page?type=all-eventos&page=' + pageNumber + '&size=' + size;
  return fetchGetAuth(url);
}