export const NOTA = 'NOTA';
export const ELIMINATORIA = 'ELIMINATORIA';

export const tipoPremiacaoText = (perfil) => {
    if (perfil === NOTA) {
        return 'Nota';
    } else if (perfil === ELIMINATORIA) {
        return 'Eliminatória';
    }

    else {
        return '';
    }
}