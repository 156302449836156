import { SERVER_URL } from '../../constants';
import { fetchPostAuthFile, fetchRemoveAuthReturn } from '../../util/FetchUtil';

const controller = 'agencia-evento';

export const removeUploadedLogo = () => {
  const url = SERVER_URL + controller + "/remove-uploaded-logo";
  return fetchRemoveAuthReturn(url);
}

export const uploadLogo = (formData) => {
  const url = SERVER_URL + controller + '/upload-logo';
  return fetchPostAuthFile(url, formData);
}