import { SERVER_URL } from '../../../../constants';
import { fetchGetAuth, fetchPostAuth, fetchRemoveAuthReturn } from '../../../../util/FetchUtil';

const controller = 'evento-data-categoria';

export const pageEventoDataCategoria = (codEventoData, pageNumber, size) => {
  const url = SERVER_URL + controller +
    '/query-page?page=' + pageNumber + '&size=' + size + '&cod-evento-data=' + codEventoData;
  return fetchGetAuth(url);
}

export const removeEventoDataCategoria = (codigo) => {
  const url = SERVER_URL + controller + "/" + codigo;
  return fetchRemoveAuthReturn(url);
}

export const saveEventoDataCategoria = (categoria) => {
  const url = SERVER_URL + controller + "/save";
  return fetchPostAuth(url, categoria);
}

export const findByEventoCodigo = (codEvento, pageNumber, size) => {
  const url = SERVER_URL + controller +
    '/query-page?page=' + pageNumber + '&size=' + size + '&cod-evento=' + codEvento;
  return fetchGetAuth(url);
}