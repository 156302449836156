import { Grid, Paper } from "@mui/material";
import React from "react";
import UsuarioPerfilAtivo from "./UsuarioPerfilAtivo";
import CredentialK8PayContainer from "../CredentialK8Pay/CredentialK8PayContainer";
import { useSelector } from "react-redux";
import { DIRETOR_EVENTO, DIRETOR_INSTITUICAO } from "../Usuario/Perfil";
import AgenciaEventoConfContainer from "../AgenciaEventoConf/AgenciaEventoConfContainer";
import InstituicaoConfContainer from "../Instituicao/InstituicaoConfContainer";

const ConfiguracaoContainer = () => {
  const usuario = useSelector((state) => state.user);
  return (
    <Grid container spacing={1}>
      {usuario.perfil === DIRETOR_EVENTO && (
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <AgenciaEventoConfContainer />
          </Paper>
        </Grid>
      )}
      {usuario.perfil === DIRETOR_INSTITUICAO && (
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <InstituicaoConfContainer />
          </Paper>
        </Grid>
      )}
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <UsuarioPerfilAtivo />
        </Paper>
      </Grid>
      {usuario.perfil === DIRETOR_EVENTO && (
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <CredentialK8PayContainer />
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default ConfiguracaoContainer;
