import { SERVER_URL } from '../../constants';
import { fetchGetAuth, fetchPostAuth } from '../../util/FetchUtil';

const controller = 'coreografia-nota';

export const findTop1ByCoreografiaCodigoAndCurrentUser = (codCoreografia) => {
  const url = SERVER_URL + controller + '/query?cod-coreografia=' + codCoreografia + '&current-user=true';
  return fetchGetAuth(url);
}

export const generateNotaFinalPorCoreografia = (codCoreografia) => {
  const url = SERVER_URL + controller + '/generate-nota-final?cod-coreografia=' + codCoreografia;
  return fetchGetAuth(url);
}

export const generateNotaFinalPorCategoria = (codCategoria) => {
  const url = SERVER_URL + controller + '/generate-nota-final?cod-evento-data-categoria=' + codCategoria;
  return fetchGetAuth(url);
}

export const generateNPremioPorCategoria = (codCategoria) => {
  const url = SERVER_URL + controller + '/generate-premio?cod-evento-data-categoria=' + codCategoria;
  return fetchGetAuth(url);
}

export const saveCoreografiaNota = (coreografiaNota) => {
  const url = SERVER_URL + controller + "/save";
  return fetchPostAuth(url, coreografiaNota);
}
