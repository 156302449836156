import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import Button from "../UI/Button";
import Loading from "../UI/Loading";
import Title from "../UI/Title";

const MidiaTableSelectView = ({
  listMidias,
  handleChangeRowsPerPage,
  size,
  handleMidiaChangePage,
  setMidia,
  isLoading,
}) => {
  return (
    <React.Fragment>o
      <Grid item xs={12}>
        <Title>Selecione uma Mídia</Title>
      </Grid>
      <TablePagination
        component="div"
        count={listMidias.totalElements}
        page={listMidias.number ? listMidias.number : 0}
        onPageChange={handleMidiaChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Nome Música</TableCell>
            <TableCell>Nome Compositor</TableCell>
            <TableCell>Descrição Mídia</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <Loading description="Carregando Mídia..." />
          ) : (
            <>
              {listMidias.content.length === 0 ? (
                <Typography sx={{ py: 3 }}>Nenhuma mídia cadastrada</Typography>
              ) : (
                <>
                  {listMidias.content.map((midia) => (
                    <TableRow>
                      <TableCell>
                        {midia.nomeMusica ? midia.nomeMusica : ""}
                      </TableCell>
                      <TableCell>
                        {midia.nomeCompositor ? midia.nomeCompositor : ""}
                      </TableCell>
                      <TableCell>
                        {midia.descricaoMidia ? midia.descricaoMidia : ""}
                      </TableCell>

                      <TableCell align="right">
                        <Button
                          variant="outlined"
                          size="small"
                          color="secondary"
                          onClick={() => setMidia(midia)}
                        >
                          Selecionar
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={listMidias.totalElements ? listMidias.totalElements : 0}
        page={listMidias.number ? listMidias.number : 0}
        onPageChange={handleMidiaChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
    </React.Fragment>
  );
};

export default MidiaTableSelectView;
