import { Container, Grid } from '@mui/material';
import React from 'react';
import LogoImg from "../shared/assets/img/logo.jpeg";
import SigninV3 from './SigninV3';
import EventoCardContainer from '../components/Evento/EventoCardContainer';

const ContainerSignIn = () => {
    return (
        <>
            <Container maxWidth="lg">
                <Grid container spacing={3} justifyContent='center'>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={LogoImg} alt="Logo" />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container directio='row' spacing={3}>
                            <Grid item xs={12} sm={12} md={6}>
                                <EventoCardContainer />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <SigninV3 />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default ContainerSignIn;