export const POR_PARTICIPANTE = 'POR_PARTICIPANTE';
export const POR_PARTICIPANTE_CATEGORIA = 'POR_PARTICIPANTE_CATEGORIA';
export const POR_COREOGRAFIA = 'POR_COREOGRAFIA';

export const formaCobrancaText = (perfil) => {
    if (perfil === POR_PARTICIPANTE) {
        return 'Por Participante';
    } else if (perfil === POR_PARTICIPANTE_CATEGORIA) {
        return 'Por Participante/Categoria';
    } else if (perfil === POR_COREOGRAFIA) {
        return 'Por Coreografia';
    } else {
        return '';
    }
}