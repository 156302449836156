export const DIRETOR_EVENTO = 'DIRETOR_EVENTO';
export const DIRETOR_INSTITUICAO = 'DIRETOR_INSTITUICAO';
export const ADMIN = 'ADMIN';
export const JURADO = 'JURADO';

export const perfilText = (perfil) => {
    if (perfil === DIRETOR_EVENTO) {
        return 'Diretor Evento';
    } else if (perfil === DIRETOR_INSTITUICAO) {
        return 'Diretor Instituição';
    } else if (perfil === ADMIN) {
        return 'Admin';
    } else if (perfil === JURADO) {
        return 'Jurado';
    }

    else {
        return '';
    }
}