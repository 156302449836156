import { FormLabel, Grid, IconButton, OutlinedInput } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import ButtonCancel from "../../UI/ButtonCancel";
import ButtonSave from "../../UI/ButtonSave";
import CancelIcon from "@mui/icons-material/Cancel";
import TextFieldDateTime from "../../UI/TextFieldDateTime";

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const EventoDataForm = ({ eventoData, setEventoData, handleSave }) => {
  const handleOnChange = (event) => {
    setEventoData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleDateOnChange = (dateSelected, name) => {
    setEventoData((prevState) => ({
      ...prevState,
      [name]: dateSelected,
    }));
  };

  const handleCancel = () => {
    setEventoData(null);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <IconButton onClick={handleCancel}>
          <CancelIcon />
        </IconButton>
      </Grid>
      <FormGrid item xs={6}>
        <FormLabel htmlFor="dataHoraEvento" required>
          Data Hora Evento
        </FormLabel>
        <TextFieldDateTime
          onChange={(dateSelected) =>
            handleDateOnChange(dateSelected, "dataHoraEvento")
          }
          value={
            eventoData.dataHoraEvento
              ? new Date(eventoData.dataHoraEvento)
              : new Date()
          }
          name="dataHoraEvento"
        />
      </FormGrid>
      <Grid item xs={12}>
        <FormGrid item xs={4}>
          <FormLabel htmlFor="maximoCoreografias">
            Máximo Coreografias
          </FormLabel>
          <OutlinedInput
            id="maximoCoreografias"
            name="maximoCoreografias"
            type="number"
            value={
              eventoData.maximoCoreografias ? eventoData.maximoCoreografias : ""
            }
            onChange={handleOnChange}
            placeholder="Máximo Coreografias"
          />
        </FormGrid>
      </Grid>
      <Grid item xs={12}>
        <FormGrid item xs={4}>
          <FormLabel htmlFor="maximoParticipantes">
            Máximo Participantes
          </FormLabel>
          <OutlinedInput
            id="maximoParticipantes"
            name="maximoParticipantes"
            type="number"
            value={
              eventoData.maximoParticipantes
                ? eventoData.maximoParticipantes
                : ""
            }
            onChange={handleOnChange}
            placeholder="Máximo Participantes"
          />
        </FormGrid>
      </Grid>

      <FormGrid item xs={12}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <ButtonCancel handleCancel={handleCancel} />
          </Grid>
          <Grid item>
            <ButtonSave handleSave={handleSave} />
          </Grid>
        </Grid>
      </FormGrid>
    </Grid>
  );
};

export default EventoDataForm;
