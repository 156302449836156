import { CircularProgress, Grid } from '@mui/material';
import React from 'react';


const Loading = ({ description }) => {

    return (
        <Grid sx={{ p: 2, justifyContent: 'center' }} container spacing={3} direction='row'>
            <Grid item>
                <CircularProgress color="primary" />
            </Grid>
            <Grid item>
                <p>{description}</p>
            </Grid>

        </Grid>

    );
}

export default Loading;