import { FormLabel, Grid, OutlinedInput, Paper } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/system";
import ButtonSave from "../UI/ButtonSave";
import { useSelector } from "react-redux";
import { changePassword } from "./api-usuario";
import { errorMessage } from "../util/ConfirmAlertUtil";
import { toast } from "react-toastify";

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const UsuarioFormSenha = () => {
  const usuario = useSelector(state => state.user);
  const [usuarioPassword, setUsuarioPassword] = useState({
    usuario: usuario
  });

  const handleOnChange = (event) => {
    setUsuarioPassword({
      ...usuarioPassword,
      [event.target.name]: event.target.value
    })
  }

  const clickSubmit = (event) => {
    event.preventDefault();

    if (usuarioPassword.password1 !== usuarioPassword.password2) {
      toast.error("Digite a mesma senha para os dois campos de senha.");

      return;
    }

    changePassword(usuarioPassword).then(data => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          toast.success("Salvo com sucesso.");

        }
      }

    });

  }




  return (
    <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
      <Grid container spacing={3}>

        <Grid item xs={12}>
          <FormGrid item xs={4}>
            <FormLabel htmlFor="nome" >
              Nome
            </FormLabel>
            <OutlinedInput
              id="nome"
              name="nome"
              type="name"
              value={usuario ? usuario.nome : ""}
              onChange={handleOnChange}
              placeholder="Nome"
              readOnly={true}
            />
          </FormGrid>
        </Grid>
        <Grid item xs={12}>
          <FormGrid item xs={4}>
            <FormLabel htmlFor="email" >
              Email
            </FormLabel>
            <OutlinedInput
              id="email"
              name="email"
              type="name"
              value={usuario ? usuario.email : ""}
              onChange={handleOnChange}
              placeholder="Email"
              readOnly={true}
            />
          </FormGrid>
        </Grid>
        <Grid item xs={12}>
          <FormGrid item xs={4}>
            <FormLabel htmlFor="password1" >
              Senha
            </FormLabel>
            <OutlinedInput
              id="password1"
              name="password1"
              type="password"
              value={usuarioPassword && usuarioPassword.password1 ? usuario.password1 : ""}
              onChange={handleOnChange}
              placeholder="Senha"
            />
          </FormGrid>
        </Grid>
        <Grid item xs={12}>
          <FormGrid item xs={4}>
            <FormLabel htmlFor="password2" >
              Confirma Senha
            </FormLabel>
            <OutlinedInput
              id="password2"
              name="password2"
              type="password"
              value={usuarioPassword && usuarioPassword.password2 ? usuario.password2 : ""}
              onChange={handleOnChange}
              placeholder="Confirma Senha"
            />
          </FormGrid>
        </Grid>

        <FormGrid item xs={12}>
          <Grid container direction="row" justifyContent="space-between">

            <Grid item>
              <ButtonSave handleSave={clickSubmit} />
            </Grid>
          </Grid>
        </FormGrid>
      </Grid>
    </Paper>
  );
};

export default UsuarioFormSenha;
