import { Dialog, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { errorMessage } from "../util/ConfirmAlertUtil";
import MidiaTableSelectView from "./MidiaTableSelectView";
import { page } from "./api-midia";

const MidiaFormSearchDialog = ({ setMidia, openDialog, handleCloseDialog }) => {
  const [listMidias, setListMidias] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [size, setSize] = useState(10);

  useEffect(() => {
    fetchMidia(0);
    // eslint-disable-next-line
  }, []);

  const handleMidiaChangePage = (newPage) => {
    fetchMidia(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
  };

  const fetchMidia = (newPage) => {
    page(newPage, size).then((data) => {
      setIsLoading(true);
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListMidias(data);
        }
      }
      setIsLoading(false);
    });
  };

  return (
    <Dialog onClose={handleCloseDialog} open={openDialog}>
      <Grid container spacing={3} sx={{ p: 3 }}>
        <Grid item xs={12}>
          <MidiaTableSelectView
            listMidias={listMidias}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            size={size}
            handleMidiaChangePage={handleMidiaChangePage}
            setMidia={setMidia}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default MidiaFormSearchDialog;
