import {
  Grid,
} from "@mui/material";
import React from "react";
import Title from "../UI/Title";
import { ELIMINATORIA, NOTA, tipoPremiacaoText } from "./TipoPremiacao";
import Button from "../UI/Button";

const CoreografiaNotaPremiacao = ({
  coreografia,
  handleGenerateNotaFinalPorCoreografia,
  handleGeneratePremioPorCategoria,
  isLoadingAction
}) => {



  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Title>Coreografia Nota Premiação</Title>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <p>
            <span style={{ fontWeight: 'bold' }}>Nota Final: </span>
            {coreografia.nota
              ? coreografia.nota
              : ""}
          </p>
        </Grid>
        <Grid item xs={12}>
          <p>
            <span style={{ fontWeight: 'bold' }}>Tipo Premiação Categoria: </span>
            {coreografia.eventoDataCategoria.tipoPremiacao
              ? tipoPremiacaoText(coreografia.eventoDataCategoria.tipoPremiacao)
              : ""}
          </p>
        </Grid>
        {coreografia.eventoDataCategoria.tipoPremiacao &&
          coreografia.eventoDataCategoria.tipoPremiacao === ELIMINATORIA &&
          <>
            <Grid item xs={12}>
              <p>
                <span style={{ fontWeight: 'bold' }}>Posição: </span>
                {coreografia.posicaoPremiacao
                  ? coreografia.posicaoPremiacao + "º Lugar"
                  : ""}
              </p>
            </Grid>
          </>
        }
        {coreografia.eventoDataCategoria.tipoPremiacao &&
          coreografia.eventoDataCategoria.tipoPremiacao === NOTA &&
          <>
            <Grid item xs={12}>
              <p>
                <span style={{ fontWeight: 'bold' }}>Nota Corte: </span>
                {coreografia.nota
                  ? coreografia.nota
                  : ""}
              </p>
            </Grid>
            <Grid item xs={12}>
              <p>
                <span style={{ fontWeight: 'bold' }}>Passou Nota: </span>
                {coreografia.passouNota
                  ? "Sim"
                  : "Não"}
              </p>
            </Grid>
          </>
        }

        <Grid item xs={12}>
          <Grid container direction='row' spacing={5} justifyContent='center'>
            <Grid item>
              <Button
                isLoadingAction={isLoadingAction}
                onClick={handleGenerateNotaFinalPorCoreografia}
                color='secondary'>Gerar Nota Final por Coreografia</Button>
            </Grid>
            <Grid item>
              <Button
                isLoadingAction={isLoadingAction}
                onClick={handleGeneratePremioPorCategoria}
                color='secondary'>Gerar Premiação</Button>
            </Grid>
          </Grid>
        </Grid>



      </Grid>

    </React.Fragment>
  );
};

export default CoreografiaNotaPremiacao;
