import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "../../UI/Button";
import Loading from "../../UI/Loading";
import Title from "../../UI/Title";
import { JURADO } from "../Perfil";

const UsuarioPerfilTable = ({
  listUsuarioPerfil,
  handleDelete,
  isLoadingRemove,
  setUsuarioPerfil,
  handleChangePage,
  isLoading,
  size,
  handleChangeRowsPerPage,
}) => {
  const handleNovoRegistroJurado = () => {
    setUsuarioPerfil({
      eNovo: true,
      perfil: JURADO
    });
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Title>Usuário Perfil</Title>
      </Grid>
      <Grid item sx={3}>
        <Button onClick={handleNovoRegistroJurado}>Novo Jurado</Button>
      </Grid>
      <TablePagination
        component="div"
        count={listUsuarioPerfil.totalElements}
        page={listUsuarioPerfil.number ? listUsuarioPerfil.number : 0}
        onPageChange={handleChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Perfil</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <Loading description="Carregando Usuário Perfil..." />
          ) : (
            <>
              {listUsuarioPerfil.content.length === 0 ? (
                <Typography sx={{ py: 3 }}>
                  Nenhum usuário perfil cadastrado
                </Typography>
              ) : (
                <>
                  {listUsuarioPerfil.content.map((usuarioPerfil) => (
                    <TableRow>
                      <TableCell>
                        {usuarioPerfil.usuario ? usuarioPerfil.usuario.nome : ""}
                      </TableCell>
                      <TableCell>
                        {usuarioPerfil.usuario ? usuarioPerfil.usuario.email : ""}
                      </TableCell>
                      <TableCell>
                        {usuarioPerfil.perfil ? usuarioPerfil.perfil : ""}
                      </TableCell>

                      <TableCell align="right">
                        {isLoadingRemove ? (
                          <CircularProgress
                            sx={{ mr: 5 }}
                            color="secondary"
                            size={20}
                          />
                        ) : (
                          <DeleteIcon
                            sx={{ mr: 5 }}
                            style={{ cursor: "pointer" }}
                            fontSize="small"
                            onClick={() => {
                              handleDelete(usuarioPerfil.codigo);
                            }}
                            color="secondary"
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={
          listUsuarioPerfil.totalElements ? listUsuarioPerfil.totalElements : 0
        }
        page={listUsuarioPerfil.number ? listUsuarioPerfil.number : 0}
        onPageChange={handleChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
    </React.Fragment>
  );
};

export default UsuarioPerfilTable;
