import { FormLabel, Grid, IconButton, OutlinedInput } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import ButtonCancel from "../UI/ButtonCancel";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "../UI/Button";
import { Save } from "@mui/icons-material";
import Title from "../UI/Title";
import { findUnregisteredCoreografiasByEventoDataCodigo } from "./api-apresentacao";
import { errorMessage } from "../util/ConfirmAlertUtil";
import CoreografiaTableSelectViewList from "../Coreografia/CoreografiaTableSelectViewList";

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const ApresentacaoFormAdicionar = ({
  apresentacaoIntervalo,
  setApresentacaoIntervalo,
  handleSaveAdicionarIntervalo,
  isLoadingAction
}) => {

  const [listCoreografias, setListCoreografias] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchUnregisteredCoreografias();
    //eslint-disable-next-line
  }, []);

  const fetchUnregisteredCoreografias = () => {
    setIsLoading(true);
    findUnregisteredCoreografiasByEventoDataCodigo(apresentacaoIntervalo.apresentacaoInserir.eventoData.codigo).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListCoreografias(data);
        }
      }
      setIsLoading(false);
    })
  }

  const handleOnChange = (event) => {
    setApresentacaoIntervalo((prevState) => ({
      ...prevState,
      apresentacaoInserir: {
        ...prevState.apresentacaoInserir,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleCancel = () => {
    setApresentacaoIntervalo(null);
  };

  const handleSetCoreografia = (coreografia) => {
    setApresentacaoIntervalo((prevState) => ({
      ...prevState,
      apresentacaoInserir: {
        ...prevState.apresentacaoInserir,
        coreografia: coreografia,
      },
    }));
  }

  const handleRemoverCoreografiaSelecionada = () => {
    setApresentacaoIntervalo((prevState) => ({
      ...prevState,
      apresentacaoInserir: {
        ...prevState.apresentacaoInserir,
        coreografia: null,
      },
    }));
  }




  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <IconButton onClick={handleCancel}>
          <CancelIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Title>Formulário Adicionar Apresentação (Coreografia/Intervalo)</Title>
      </Grid>

      {apresentacaoIntervalo.apresentacaoInserir.apresentacaoIntervalo ?
        <>
          <Grid item xs={4}>
            <FormGrid item xs={12}>
              <FormLabel htmlFor="duracao">Duração (minutos)</FormLabel>
              <OutlinedInput
                id="duracao"
                name="duracao"
                type="number"
                value={
                  apresentacaoIntervalo.apresentacaoInserir &&
                    apresentacaoIntervalo.apresentacaoInserir.duracao
                    ? apresentacaoIntervalo.apresentacaoInserir.duracao
                    : ""
                }
                onChange={handleOnChange}
                placeholder="Duração"
              />
            </FormGrid>
          </Grid>

          <Grid item xs={12}>
            <FormGrid item xs={12}>
              <FormLabel htmlFor="descricao">Descricao</FormLabel>
              <OutlinedInput
                id="descricao"
                name="descricao"
                multiline
                rows={4}
                inputProps={{ maxLength: 200 }}
                type="name"
                value={
                  apresentacaoIntervalo.apresentacaoInserir &&
                    apresentacaoIntervalo.apresentacaoInserir.descricao
                    ? apresentacaoIntervalo.apresentacaoInserir.descricao
                    : ""
                }
                onChange={handleOnChange}
                placeholder="Descrição"
              />
            </FormGrid>
          </Grid>
          <Grid item xs={12}>
            <FormGrid item xs={12}>
              <FormLabel htmlFor="conteudoApresentador">
                Conteúdo Apresentador
              </FormLabel>
              <OutlinedInput
                id="conteudoApresentador"
                multiline
                rows={4}
                name="conteudoApresentador"
                inputProps={{ maxLength: 300 }}
                type="name"
                value={
                  apresentacaoIntervalo.apresentacaoInserir &&
                    apresentacaoIntervalo.apresentacaoInserir.conteudoApresentador
                    ? apresentacaoIntervalo.apresentacaoInserir.conteudoApresentador
                    : ""
                }
                onChange={handleOnChange}
                placeholder="Conteúdo Apresentador"
              />
            </FormGrid>
          </Grid>
        </> :
        <>
          {apresentacaoIntervalo.apresentacaoInserir.coreografia ?
            <>
              <Grid item xs={12}>
                <p><span style={{ fontWeight: 'bold' }}>Coreografia: </span>
                  {apresentacaoIntervalo.apresentacaoInserir.coreografia.descricao}
                </p>
              </Grid>
              <Grid item xs={12}>
                <Button onClick={handleRemoverCoreografiaSelecionada}>Selecionar Outra Coreografia</Button>
              </Grid>
            </> :
            <>
              <Grid item>
                <CoreografiaTableSelectViewList
                  listCoreografias={listCoreografias}
                  setCoreografia={handleSetCoreografia}
                  isLoading={isLoading}
                />
              </Grid>
            </>
          }

        </>


      }




      <FormGrid item xs={12}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <ButtonCancel handleCancel={handleCancel} />
          </Grid>
          {apresentacaoIntervalo.eNovo && (apresentacaoIntervalo.apresentacaoInserir.coreografia ||
            apresentacaoIntervalo.apresentacaoInserir.duracao) && (
              <>
                {apresentacaoIntervalo.apresentacaoReferencia.coreografia &&
                  <Grid item>
                    <Button isLoadingAction={isLoadingAction} onClick={() => handleSaveAdicionarIntervalo("antes")}>
                      <Save sx={{ mr: 2 }} />
                      Salvar Antes
                    </Button>
                  </Grid>
                }

                <Grid item>
                  <Button isLoadingAction={isLoadingAction} onClick={() => handleSaveAdicionarIntervalo("depois")}>
                    <Save sx={{ mr: 2 }} />
                    Salvar Depois
                  </Button>
                </Grid>
              </>
            )}
        </Grid>
      </FormGrid>
    </Grid >
  );
};

export default ApresentacaoFormAdicionar;
