import React, { useState } from 'react';
import { changePassword } from '../auth/api-auth';
import {
    Link,
    useParams
} from "react-router-dom";
// import { useHistory } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Container, TextField, Typography } from '@mui/material';
import Button from '../components/UI/Button';
import LogoImg from "../shared/assets/img/logo.jpeg";

export default function ResetPassword() {
    const [message, setMessage] = useState({
        content: '',
        type: ''
    })
    const [isLoading, setIsLoading] = useState(false);
    const [usuarioPassword, setUsuarioPassword] = useState();
    const [passwordChanged, setPasswordChanged] = useState(false);
    const { codResetPassword } = useParams();
    // const history = useHistory();
    const navigate = useNavigate();


    const clickSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);

        if (usuarioPassword.password1 !== usuarioPassword.password2) {
            setMessage({
                content: 'Digite a mesma senha para os dois campos de senha.',
                type: 'ERROR'
            });
            setIsLoading(false);
            return;
        }

        changePassword(usuarioPassword).then(data => {
            if (data.error) {
                if (data.message === 'Already used') {
                    setMessage({
                        content: "Link já utilizado.",
                        type: 'ERROR'
                    });
                } else if (data.message === 'Limit Time expired') {
                    setMessage({
                        content: "Tempo Limite para resetar a senha expirado. Refaça o processo para resetar a senha.",
                        type: 'ERROR'
                    });
                }
                else {
                    console.log(data.error);
                }

            } else {
                setMessage({
                    content: "Senha alterada com sucesso.",
                    type: 'SUCCESS'
                });
                setPasswordChanged(true);
            }
            setIsLoading(false);
        });

    }

    const handleChange = (event) => {
        setUsuarioPassword({
            ...usuarioPassword,
            [event.target.name]: event.target.value,
            codigoResetPassword: codResetPassword
        });
    }



    return (
        <div>
            <Container component="main" maxWidth="xs">
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <Link to={'/'}> <img src={LogoImg}
                        style={{
                            width: '100%',
                        }}
                        alt="Logo" /></Link>

                    <Typography variant="h5">
                        Resetar Senha
                    </Typography>

                    <form onSubmit={clickSubmit}>

                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            disabled={passwordChanged}
                            fullWidth
                            name="password1"
                            label="Senha"
                            type="password"
                            id="password1"
                            autoComplete="current-password"
                            onChange={handleChange}
                            value={usuarioPassword && usuarioPassword.password1 ? usuarioPassword.password1 : ''}
                        />

                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            disabled={passwordChanged}
                            name="password2"
                            label="Confirma Senha"
                            type="password"
                            id="password2"
                            autoComplete="current-password"
                            onChange={handleChange}
                            value={usuarioPassword && usuarioPassword.password2 ? usuarioPassword.password2 : ''}
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={isLoading || passwordChanged}
                        >
                            {isLoading && <CircularProgress style={{
                                marginRight: '10px',
                                marginLeft: '10px'
                            }}
                                color="primary"
                                size={20}
                            />
                            }
                            Trocar Senha
                        </Button>
                        {message.type === 'SUCCESS' ?
                            <>
                                <Typography style={{
                                    border: '1px solid darkgreen',
                                    padding: '30px',
                                    borderRadius: '30px',
                                    backgroundColor: 'palegreen',
                                }} >
                                    {message.content}
                                </Typography>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate('/')}
                                >
                                    Voltar para Login
                                </Button>
                            </> :
                            ''
                        }
                        {message.type === 'ERROR' ?
                            <Typography style={{
                                border: '1px solid Red',
                                padding: '30px',
                                borderRadius: '30px',
                                backgroundColor: 'Tomato',
                            }} >
                                {message.content}
                            </Typography> :
                            ''
                        }

                    </form>

                </div>
            </Container>
        </div>
    );
}