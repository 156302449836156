import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "../../../UI/Button";
import Loading from "../../../UI/Loading";
import Title from "../../../UI/Title";

const EventoDataJuradoTable = ({
  listEventoDataJurado,
  handleDelete,
  isLoadingRemove,
  setEventoDataJurado,
  handleChangePage,
  isLoading,
  size,
  handleChangeRowsPerPage,
  eventoData,
}) => {
  const handleNovoRegistro = () => {
    setEventoDataJurado({
      eNovo: true,
      eventoData: eventoData,
    });
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Title>Evento Data Jurado</Title>
      </Grid>
      <Grid item sx={3}>
        <Button onClick={handleNovoRegistro}>Novo Registro</Button>
      </Grid>
      <TablePagination
        component="div"
        count={listEventoDataJurado.totalElements}
        page={listEventoDataJurado.number ? listEventoDataJurado.number : 0}
        onPageChange={handleChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Email</TableCell>

            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <Loading description="Carregando Evento Data Jurados..." />
          ) : (
            <>
              {listEventoDataJurado.content.length === 0 ? (
                <Typography sx={{ py: 3 }}>
                  Nenhum evento data jurado cadastrado
                </Typography>
              ) : (
                <>
                  {listEventoDataJurado.content.map((eventoDataJurado) => (
                    <TableRow>
                      <TableCell>
                        {eventoDataJurado.usuarioPerfil.usuario
                          ? eventoDataJurado.usuarioPerfil.usuario.nome
                          : ""}
                      </TableCell>
                      <TableCell>
                        {eventoDataJurado.usuarioPerfil.usuario
                          ? eventoDataJurado.usuarioPerfil.usuario.email
                          : ""}
                      </TableCell>

                      <TableCell align="right">
                        {isLoadingRemove ? (
                          <CircularProgress
                            sx={{ mr: 5 }}
                            color="secondary"
                            size={20}
                          />
                        ) : (
                          <DeleteIcon
                            sx={{ mr: 5 }}
                            style={{ cursor: "pointer" }}
                            fontSize="small"
                            onClick={() => {
                              handleDelete(eventoDataJurado.codigo);
                            }}
                            color="secondary"
                          />
                        )}
                        {/* <Button
                          variant="outlined"
                          size="small"
                          color="secondary"
                          onClick={() => setEventoDataJurado(eventoDataJurado)}
                        >
                          Selecionar
                        </Button> */}
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={
          listEventoDataJurado.totalElements ? listEventoDataJurado.totalElements : 0
        }
        page={listEventoDataJurado.number ? listEventoDataJurado.number : 0}
        onPageChange={handleChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
    </React.Fragment>
  );
};

export default EventoDataJuradoTable;
