import { Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';


export default function NotFoundError() {
    return (
        <div>
            <Typography css={css({
                flex: '1 1 100%',
                paddingTop: '20px;',
                marginBottom: '20px'
            })} variant="h5">
                Página não encontrada...
            </Typography>

            <Typography css={css({
                flex: '1 1 100%',
                paddingTop: '20px;',
                marginBottom: '20px'
            })} variant="h6">
                <Link to="/">
                    Home
                </Link>
            </Typography>

        </div>
    );
}