import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Loading from "../../../../UI/Loading";
import Title from "../../../../UI/Title";

const EDCIndicacaoTable = ({
  listEDCIndicacoes,
  handleDelete,
  isLoadingRemove,
  isLoading,
}) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Title>Indicações</Title>
      </Grid>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Descrição</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <Loading description="Carregando Indicações..." />
          ) : (
            <>
              {listEDCIndicacoes.length === 0 ? (
                <Typography sx={{ py: 3 }}>
                  Nenhuma indicação cadastrada
                </Typography>
              ) : (
                <>
                  {listEDCIndicacoes.map(
                    (edcIndicacao) => (
                      <TableRow>
                        <TableCell>
                          {edcIndicacao.indicacao.descricao
                            ? edcIndicacao.indicacao.descricao
                            : ""}
                        </TableCell>

                        <TableCell align="right">
                          {isLoadingRemove ? (
                            <CircularProgress
                              sx={{ mr: 5 }}
                              color="secondary"
                              size={20}
                            />
                          ) : (
                            <DeleteIcon
                              sx={{ mr: 5 }}
                              style={{ cursor: "pointer" }}
                              fontSize="small"
                              onClick={() => {
                                handleDelete(edcIndicacao.codigo);
                              }}
                              color="secondary"
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </>
              )}
            </>
          )}
        </TableBody>
      </Table>

    </React.Fragment>
  );
};

export default EDCIndicacaoTable;
