import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import Button from "../UI/Button";
import Loading from "../UI/Loading";
import Title from "../UI/Title";
import { dateTimeFormat } from "../../util/DateUtil";

const CoreografiaTableSelectViewList = ({
  listCoreografias,
  setCoreografia,
  isLoading,
}) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Title>Selecione uma Coreografia</Title>
      </Grid>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Evento Data Hora</TableCell>
            <TableCell>Categoria</TableCell>
            <TableCell>Coreografia</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <Loading description="Carregando Coreografias..." />
          ) : (
            <>
              {listCoreografias.length === 0 ? (
                <Typography sx={{ py: 3 }}>
                  Nenhuma Coreografia cadastrado
                </Typography>
              ) : (
                <>
                  {listCoreografias.map((coreografia) => (
                    <TableRow>
                      <TableCell>
                        {coreografia.eventoDataCategoria.eventoData
                          ? dateTimeFormat(
                            coreografia.eventoDataCategoria.eventoData
                              .dataHoraEvento
                          )
                          : ""}
                      </TableCell>
                      <TableCell>
                        {coreografia.eventoDataCategoria
                          ? coreografia.eventoDataCategoria.categoria
                            .descricao
                          : ""}
                      </TableCell>
                      <TableCell>
                        {coreografia.descricao ? coreografia.descricao : ""}
                      </TableCell>

                      <TableCell align="right">
                        <Button
                          variant="outlined"
                          size="small"
                          color="secondary"
                          onClick={() => setCoreografia(coreografia)}
                        >
                          Selecionar
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </>
          )}
        </TableBody>
      </Table>

    </React.Fragment>
  );
};

export default CoreografiaTableSelectViewList;
