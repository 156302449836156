import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { errorMessage } from "../../util/ConfirmAlertUtil";
import { page, remove, saveEventoData } from "./api-evento-data";
import { page as pageEvento } from "../api-evento";
import { confirmAlert } from "react-confirm-alert";
import EventoDataForm from "./EventoDataForm";
import EventoDataTable from "./EventoDataTable";
import EventoTableSelectView from "../EventoTableSelectView";
import EventoProfile from "../EventoProfile";

const EventoDataContainer = () => {
  const [listEventos, setListEventos] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [evento, setEvento] = useState(null);
  const [listEventoDatas, setListEventoDatas] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [eventoData, setEventoData] = useState(null);
  const [isLoadingRemove, setIsLoadingRemove] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEvento, setIsLoadingEvento] = useState(false);
  const [size, setSize] = useState(10);
  const [sizeEvento, setSizeEvento] = useState(10);

  useEffect(() => {
    fetchDataEvento(0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (evento) {
      fetchData(0);
    }
    // eslint-disable-next-line
  }, [evento]);

  const handleChangePage = (event, newPage) => {
    if (evento) {
      fetchData(newPage);
    }
  };

  const fetchData = (newPage) => {
    setIsLoading(true);
    page(evento.codigo, newPage, size).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListEventoDatas(data);
        }
      }
      setIsLoading(false);
    });
  };

  const fetchDataEvento = (newPage) => {
    setIsLoadingEvento(true);
    pageEvento(newPage, sizeEvento).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListEventos(data);
        }
      }
      setIsLoadingEvento(false);
    });
  };

  const handleEventoChangePage = (event, newPage) => {
    fetchDataEvento(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
  };

  const handleDelete = (codigo) => {
    confirmAlert({
      title: "Alerta",
      message: "Deseja deletar este evento data?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            setIsLoadingRemove(true);
            remove(codigo).then((data) => {
              if (data.error) {
                errorMessage(data.message);
              } else {
                setListEventoDatas((eventoElement) => ({
                  ...eventoElement,
                  content: eventoElement.content.filter(
                    (element) => element.codigo !== codigo
                  ),
                }));
                setEventoData(null);
                toast.success("Registro excluído com Sucesso.");
              }
              setIsLoadingRemove(false);
            });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const handleSave = () => {
    if (eventoData.descricao === null || eventoData.descricao === "") {
      errorMessage("Campo Descrição não pode ser vazio.");
    }
    const eNovo = eventoData.eNovo;
    saveEventoData(eventoData).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          if (eNovo) {
            setListEventoDatas((prevState) => ({
              ...prevState,
              content: [data, ...prevState.content],
            }));
          } else {
            setListEventoDatas((prevState) => ({
              ...prevState,
              content: prevState.content.map((content) => {
                if (content.codigo === data.codigo) {
                  return data;
                } else {
                  return content;
                }
              }),
            }));
          }
          setEventoData(data);
          toast.success("Operação Realizada com Sucesso.");
        }
      }
    });
  };

  const handleEventoChangeRowsPerPage = (event) => {
    setSizeEvento(event.target.value);
  };

  const handleTrocaEvento = () => {
    setEvento(null);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {evento ? (
            <Grid container direction="column" spacing={2}>
              <Grid item sx={{ display: "flex" }}>
                <EventoProfile
                  evento={evento}
                  handleTrocaEvento={handleTrocaEvento}
                />
              </Grid>
              <Grid item>
                {eventoData ? (
                  <EventoDataForm
                    eventoData={eventoData}
                    setEventoData={setEventoData}
                    handleSave={handleSave}
                  />
                ) : (
                  <EventoDataTable
                    listEventoDatas={listEventoDatas}
                    handleDelete={handleDelete}
                    isLoadingRemove={isLoadingRemove}
                    setEventoData={setEventoData}
                    handleChangePage={handleChangePage}
                    isLoading={isLoading}
                    size={size}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    evento={evento}
                  />
                )}
              </Grid>
            </Grid>
          ) : (
            <EventoTableSelectView
              listEventos={listEventos}
              handleEventoChangeRowsPerPage={handleEventoChangeRowsPerPage}
              sizeEvento={sizeEvento}
              handleEventoChangePage={handleEventoChangePage}
              setEvento={setEvento}
              isLoadingEvento={isLoadingEvento}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default EventoDataContainer;
