import React, { useEffect, useState } from 'react';
import { pagePublicPermissaoDivulgacaoData } from './api-evento';
import { errorMessage } from '../util/ConfirmAlertUtil';
import EventoCard from './EventoCard';
import { Grid, TablePagination } from '@mui/material';

const EventoCardContainer = () => {
    const [eventos, setEventos] = useState({
        content: [],
        size: 0,
        number: 0,
        totalElements: 0,
    });
    const [size, setSize] = useState(2);

    useEffect(() => {
        fetchEventoData(0);
        // eslint-disable-next-line
    }, [size]);

    const handleChangePage = (event, newPage) => {
        fetchEventoData(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setSize(event.target.value);
    };

    const fetchEventoData = (pageNumber) => {
        pagePublicPermissaoDivulgacaoData(pageNumber, size).then((data) => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setEventos(data);
                }
            }
        })
    }

    return (
        <Grid container direction='column' spacing={3}>
            <Grid item>
                <Grid container direction='row' spacing={2}>
                    <Grid item>
                        {eventos.content.map((e) => (
                            <EventoCard evento={e} />
                        ))}
                    </Grid>
                </ Grid>
            </Grid>
            {eventos.totalElements > 0 &&
                <Grid item>
                    <TablePagination
                        component="div"
                        count={eventos.totalElements ? eventos.totalElements : 0}
                        page={eventos.number ? eventos.number : 0}
                        onPageChange={handleChangePage}
                        rowsPerPage={size}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Linhas por página:"
                        labelDisplayedRows={({ from, to, count }) =>
                            `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
                        }
                    />
                </Grid>

            }

        </Grid>

    );
}

export default EventoCardContainer;