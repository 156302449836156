import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "../../UI/Button";
import Loading from "../../UI/Loading";
import Title from "../../UI/Title";
import { dateTimeFormat } from "../../../util/DateUtil";

const EventoDataTable = ({
  listEventoDatas,
  handleDelete,
  isLoadingRemove,
  setEventoData,
  handleChangePage,
  isLoading,
  size,
  handleChangeRowsPerPage,
  evento,
}) => {
  const handleNovoRegistro = () => {
    setEventoData({
      eNovo: true,
      evento: evento,
    });
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Title>Evento Data</Title>
      </Grid>
      <Grid item sx={3}>
        <Button onClick={handleNovoRegistro}>Novo Registro</Button>
      </Grid>
      <TablePagination
        component="div"
        count={listEventoDatas.totalElements}
        page={listEventoDatas.number ? listEventoDatas.number : 0}
        onPageChange={handleChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Descrição</TableCell>
            <TableCell>País</TableCell>
            <TableCell>Estado</TableCell>
            <TableCell>Cidade</TableCell>
            <TableCell>Local</TableCell>
            <TableCell>Data Hora</TableCell>
            <TableCell>Máximo Coreografias</TableCell>
            <TableCell>Máximo Participantes</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <Loading description="Carregando Eventos..." />
          ) : (
            <>
              {listEventoDatas.content.length === 0 ? (
                <Typography sx={{ py: 3 }}>
                  Nenhum evento data cadastrado
                </Typography>
              ) : (
                <>
                  {listEventoDatas.content.map((eventoData) => (
                    <TableRow>
                      <TableCell>
                        {eventoData.evento && eventoData.evento.descricao
                          ? eventoData.evento.descricao
                          : ""}
                      </TableCell>
                      <TableCell>
                        {eventoData.evento && eventoData.evento.pais
                          ? eventoData.evento.pais
                          : ""}
                      </TableCell>
                      <TableCell>
                        {eventoData.evento && eventoData.evento.estado
                          ? eventoData.evento.estado
                          : ""}
                      </TableCell>
                      <TableCell>
                        {eventoData.evento && eventoData.evento.cidade
                          ? eventoData.evento.cidade
                          : ""}
                      </TableCell>
                      <TableCell>
                        {eventoData.evento && eventoData.evento.local
                          ? eventoData.evento.local
                          : ""}
                      </TableCell>
                      <TableCell>
                        {eventoData.dataHoraEvento
                          ? dateTimeFormat(eventoData.dataHoraEvento)
                          : ""}
                      </TableCell>
                      <TableCell>
                        {eventoData.maximoCoreografias
                          ? eventoData.maximoCoreografias
                          : ""}
                      </TableCell>
                      <TableCell>
                        {eventoData.maximoParticipantes
                          ? eventoData.maximoParticipantes
                          : ""}
                      </TableCell>

                      <TableCell align="right">
                        {isLoadingRemove ? (
                          <CircularProgress
                            sx={{ mr: 5 }}
                            color="secondary"
                            size={20}
                          />
                        ) : (
                          <DeleteIcon
                            sx={{ mr: 5 }}
                            style={{ cursor: "pointer" }}
                            fontSize="small"
                            onClick={() => {
                              handleDelete(eventoData.codigo);
                            }}
                            color="secondary"
                          />
                        )}
                        <Button
                          variant="outlined"
                          size="small"
                          color="secondary"
                          onClick={() => setEventoData(eventoData)}
                        >
                          Selecionar
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={
          listEventoDatas.totalElements ? listEventoDatas.totalElements : 0
        }
        page={listEventoDatas.number ? listEventoDatas.number : 0}
        onPageChange={handleChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
    </React.Fragment>
  );
};

export default EventoDataTable;
