import { Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { errorMessage } from "../util/ConfirmAlertUtil";
import {
  deleteApresentacaoById,
  findByEventoDataCodigo,
  saveAdicionarIntervalo,
  saveEditarIntervalo,
} from "./api-apresentacao";
import { page } from "../Evento/api-evento";
import EventoTableSelectView from "../Evento/EventoTableSelectView";
import EventoProfile from "../Evento/EventoProfile";
import { findByEventoCodigo } from "../Evento/EventoData/api-evento-data";
import EventoDataProfile from "../Evento/EventoData/EventoDataProfile";
import EventoDataTableSelectView from "../Evento/EventoData/EventoDataTableSelectView";
import ApresentacaoCockpit from "./ApresentacaoCockpit";
import Button from "../UI/Button";
import ApresentacaoTable from "./ApresentacaoTable";
import ApresentacaoFormAdicionar from "./ApresentacaoFormAdicionar";
import ApresentacaoFormEditar from "./ApresentacaoFormEditar";
import ApresentacaoTrocarOrdemFormDialog from "./ApresentacaoTrocarOrdemFormDialog";
import { JURADO } from "../Usuario/Perfil";
import { useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import Title from "../UI/Title";
import { toast } from "react-toastify";

const ApresentacaoContainer = () => {
  const usuario = useSelector(state => state.user);
  const [listApresentacoes, setListApresentacoes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAction, setIsLoadingAction] = useState(false);

  const [listEventos, setListEventos] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [evento, setEvento] = useState(null);
  const [listEventoDatas, setListEventoDatas] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [eventoData, setEventoData] = useState(null);
  const [size, setSize] = useState(10);
  const cockpitKey = "cockpit";
  const formularioKey = "formulario";
  const [typeApresentacao, setTypeApresentacao] = useState(null);
  const [apresentacaoIntervalo, setApresentacaoIntervalo] = useState(null);
  const [
    openApresentacaoTrocarOrdemDialog,
    setOpenApresentacaoTrocarOrdemDialog,
  ] = useState(false);

  useEffect(() => {
    fetchEventos(0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (evento) {
      fetchEventoData(0);
    }
    // eslint-disable-next-line
  }, [evento]);

  useEffect(() => {
    if (eventoData) {
      fetchApresentacoes();
    }
    // eslint-disable-next-line
  }, [eventoData]);

  const fetchEventoData = (pageNumber) => {
    setIsLoading(true);
    findByEventoCodigo(evento.codigo, pageNumber, size).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListEventoDatas(data);
        }
      }
      setIsLoading(false);
    });
  };

  const fetchEventos = (pageNumber) => {
    setIsLoading(true);
    page(pageNumber, size).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListEventos(data);
        }
      }
      setIsLoading(false);
    });
  };

  const handleEventoDataChangePage = (event, newPage) => {
    fetchEventoData(newPage);
  };

  const fetchApresentacoes = () => {
    setIsLoading(true);
    findByEventoDataCodigo(eventoData.codigo).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListApresentacoes(data);
        }
      }
      setIsLoading(false);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
  };

  const handleEventoChangeRowsPerPage = (event) => {
    setSize(event.target.value);
  };

  const handleEventoChangePage = (event, newPage) => {
    fetchEventos(newPage);
  };

  const handleTrocaEvento = () => {
    setEvento(null);
  };

  const handleTrocaEventoData = () => {
    setEventoData(null);
  };

  const handleAdicionarIntervalo = () => {
    if (!apresentacaoIntervalo || !apresentacaoIntervalo.apresentacaoReferencia) {
      setApresentacaoIntervalo((prevState) => ({
        ...prevState,
        apresentacaoReferencia: {
          eventoData: eventoData,
          posicao: 0
        }
      }))

      // errorMessage(
      //   "Selecione uma Apresentação para ter como referência para adicionar antes ou depois da mesma."
      // );
      // return;
    }
    setApresentacaoIntervalo((prevState) => ({
      ...prevState,
      apresentacaoInserir: {
        eventoData: eventoData,
        apresentacaoIntervalo: true,
      },
      eNovo: true,
    }));
  };
  const handleAdicionarCoreografia = () => {
    if (!apresentacaoIntervalo || !apresentacaoIntervalo.apresentacaoReferencia) {
      setApresentacaoIntervalo((prevState) => ({
        ...prevState,
        apresentacaoReferencia: {
          eventoData: eventoData, 
          posicao: 0
        }
      }))
      // errorMessage(
      //   "Selecione uma Apresentação para ter como referência para adicionar antes ou depois da mesma."
      // );
      // return;
    }
    setApresentacaoIntervalo((prevState) => ({
      ...prevState,
      apresentacaoInserir: {
        eventoData: eventoData,
        apresentacaoIntervalo: false,
      },
      eNovo: true,
    }));
  };
  const handleEditarIntervalo = () => {
    if (
      apresentacaoIntervalo === null ||
      apresentacaoIntervalo.apresentacaoReferencia === null
    ) {
      errorMessage("Selecione uma Apresentação para editar.");
      return;
    }
    setApresentacaoIntervalo((prevState) => ({
      ...prevState,
      editar: true,
    }));
  };
  const handleRemover = () => {
    confirmAlert({
      title: "Alerta",
      message: "Deseja deletar esta coreografia?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            if (
              apresentacaoIntervalo === null ||
              apresentacaoIntervalo.apresentacaoReferencia === null
            ) {
              errorMessage("Selecione uma Apresentação para editar.");
              return;
            }
            setIsLoadingAction(true);
            deleteApresentacaoById(apresentacaoIntervalo.apresentacaoReferencia.codigo).then((data) => {
              if (data) {
                if (data.error) {
                  errorMessage(data.message);
                } else {
                  setApresentacaoIntervalo(null);
                  setListApresentacoes((prevState) =>
                    prevState.filter((ps) =>
                      ps.codigo !== apresentacaoIntervalo.apresentacaoReferencia.codigo)
                  );
                }
              }
              setIsLoadingAction(false);
            })
          },
        },
        {
          label: "Não",
          onClick: () => { },
        },
      ],
    });



  };

  const handleSaveAdicionarIntervalo = (localizacao) => {
    const persists = {
      ...apresentacaoIntervalo,
      localizacao: localizacao,
    };
    setIsLoadingAction(true);
    saveAdicionarIntervalo(persists).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          // setApresentacaoIntervalo((prevState) => ({
          //   ...prevState,
          //   apresentacaoInserir: { ...data },
          //   eNovo: false,
          //   editar: true,
          // }));
          setApresentacaoIntervalo(null);
          fetchApresentacoes();
        }
      }
      setIsLoadingAction(false);
    });
  };
  const handleSaveEditarIntervalo = () => {
    setIsLoadingAction(true);
    saveEditarIntervalo(apresentacaoIntervalo.apresentacaoReferencia).then(
      (data) => {
        if (data) {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setApresentacaoIntervalo((prevState) => ({
              ...prevState,
              apresentacaoReferencia: { ...data },
            }));
            fetchApresentacoes();
            toast.success("Operação Realizada com sucesso.");
          }
        }
        setIsLoadingAction(false);
      }
    );
  };

  const handleCloseApresentacaoTrocarOrdemDialog = () => {
    setOpenApresentacaoTrocarOrdemDialog(false);
  };

  const handleTrocarOrdem = () => {
    setOpenApresentacaoTrocarOrdemDialog(true);
  };

  const handleRemoveMidia = () => {
    const persist = {
      ...apresentacaoIntervalo.apresentacaoReferencia,
      midia: null,
    };
    setIsLoadingAction(true);
    saveEditarIntervalo(persist).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setApresentacaoIntervalo((prevState) => ({
            ...prevState,
            apresentacaoReferencia: {
              ...data,
            },
          }));
          fetchApresentacoes();
        }
      }
      setIsLoadingAction(false);
    });
  };

  return (
    <Grid container spacing={1}>
      <ApresentacaoTrocarOrdemFormDialog
        openDialog={openApresentacaoTrocarOrdemDialog}
        handleCloseDialog={handleCloseApresentacaoTrocarOrdemDialog}
        eventoData={eventoData}
        fetchApresentacoes={fetchApresentacoes}
        isLoadingAction={isLoadingAction}
      />
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          {typeApresentacao ? (
            <>
              {evento ? (
                <>
                  {eventoData ? (
                    <>
                      <Grid container direction="column" spacing={2}>
                        <Grid item sx={{ display: "flex" }}>
                          <EventoDataProfile
                            eventoData={eventoData}
                            handleTrocaEventoData={handleTrocaEventoData}
                          />
                        </Grid>
                        {typeApresentacao === cockpitKey && (
                          <Grid item>
                            <ApresentacaoCockpit
                              listApresentacoes={listApresentacoes}
                              isLoading={isLoading}
                            />
                          </Grid>
                        )}
                        {typeApresentacao === formularioKey && (
                          <>
                            {apresentacaoIntervalo &&
                              apresentacaoIntervalo.eNovo ? (
                              <>
                                <Grid item>
                                  <ApresentacaoFormAdicionar
                                    apresentacaoIntervalo={
                                      apresentacaoIntervalo
                                    }
                                    setApresentacaoIntervalo={
                                      setApresentacaoIntervalo
                                    }
                                    handleSaveAdicionarIntervalo={
                                      handleSaveAdicionarIntervalo
                                    }
                                    isLoadingAction={isLoadingAction}
                                  />
                                </Grid>
                              </>
                            ) : (
                              <>
                                {apresentacaoIntervalo &&
                                  apresentacaoIntervalo.editar &&
                                  apresentacaoIntervalo.apresentacaoReferencia ? (
                                  <>
                                    <Grid item>
                                      <ApresentacaoFormEditar
                                        apresentacaoIntervalo={
                                          apresentacaoIntervalo
                                        }
                                        setApresentacaoIntervalo={
                                          setApresentacaoIntervalo
                                        }
                                        handleSaveEditarIntervalo={
                                          handleSaveEditarIntervalo
                                        }
                                        fetchApresentacoes={fetchApresentacoes}
                                        isLoadingAction={isLoadingAction}
                                        handleRemoveMidia={handleRemoveMidia}
                                      />
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Grid item>
                                      <ApresentacaoTable
                                        listApresentacoes={listApresentacoes}
                                        isLoading={isLoading}
                                        apresentacaoIntervalo={
                                          apresentacaoIntervalo
                                        }
                                        setApresentacaoIntervalo={
                                          setApresentacaoIntervalo
                                        }
                                        handleAdicionarIntervalo={
                                          handleAdicionarIntervalo
                                        }
                                        handleEditarIntervalo={
                                          handleEditarIntervalo
                                        }
                                        handleTrocarOrdem={handleTrocarOrdem}
                                        handleRemover={handleRemover}
                                        isLoadingAction={isLoadingAction}
                                        handleAdicionarCoreografia={handleAdicionarCoreografia}
                                      />
                                    </Grid>
                                  </>
                                )}{" "}
                              </>
                            )}
                          </>
                        )}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid container direction="column" spacing={2}>
                        <Grid item sx={{ display: "flex" }}>
                          <EventoProfile
                            evento={evento}
                            handleTrocaEvento={handleTrocaEvento}
                          />
                        </Grid>
                        <Grid item>
                          <EventoDataTableSelectView
                            listEventoDatas={listEventoDatas}
                            handleEventoDataChangePage={
                              handleEventoDataChangePage
                            }
                            isLoading={isLoading}
                            sizeEventoData={size}
                            handleEventoDataChangeRowsPerPage={
                              handleChangeRowsPerPage
                            }
                            setEventoData={setEventoData}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              ) : (
                <>
                  <EventoTableSelectView
                    listEventos={listEventos}
                    handleEventoChangeRowsPerPage={
                      handleEventoChangeRowsPerPage
                    }
                    sizeEvento={size}
                    handleEventoChangePage={handleEventoChangePage}
                    setEvento={setEvento}
                    isLoadingEvento={isLoading}
                  />
                </>
              )}
            </>
          ) : (
            <>
              <Grid container direction="row" spacing={3}>
                <Grid item xs={12}>
                  <Title>Apresentação</Title>
                </Grid>
                <Grid item xs={12}>
                  <Typography varyant="body1">Escolha um modo de visualização</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Button onClick={() => setTypeApresentacao(cockpitKey)}>
                    Cockpit
                  </Button>
                </Grid>
                {usuario.perfil !== JURADO &&
                  <Grid item xs={3}>
                    <Button onClick={() => setTypeApresentacao(formularioKey)}>
                      Formulário
                    </Button>
                  </Grid>
                }

              </Grid>
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ApresentacaoContainer;
