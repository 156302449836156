import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { errorMessage } from "../../../util/ConfirmAlertUtil";
import {
  pageEventoDataCategoria,
  removeEventoDataCategoria,
  saveEventoDataCategoria,
} from "./api-evento-data-categoria";
import { confirmAlert } from "react-confirm-alert";
import EventoDataCategoriaTable from "./EventoDataCategoriaTable";
import EventoDataTableSelectView from "../EventoDataTableSelectView";
import EventoDataProfile from "../EventoDataProfile";
import { findByAgenciaEventoCodigo } from "../api-evento-data";
import EventoDataCategoriaForm from "./EventoDataCategoriaForm";
import { generateNotaFinalPorCategoria, generateNPremioPorCategoria } from "../../../CoreografiaNota/api-coreografia-nota";

const EventoDataCategoriaContainer = () => {
  const [listEventoDatas, setListEventoDatas] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [eventoData, setEventoData] = useState(null);
  const [listEventoDataCategorias, setListEventoDataCategorias] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [eventoDataCategoria, setEventoDataCategoria] = useState(null);
  const [isLoadingRemove, setIsLoadingRemove] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [isLoadingAction, setIsLoadingAction] = useState(false);

  useEffect(() => {
    fetchEventoData(0);
    // eslint-disable-next-line
  }, [size]);

  useEffect(() => {
    if (eventoData) {
      fetchEventoDataCategoria(0);
    }
    // eslint-disable-next-line
  }, [size, eventoData]);


  const handleChangePageEventoData = (event, newPage) => {
    if (eventoData) {
      fetchEventoData(newPage);
    }
  };

  const fetchEventoData = (newPage) => {
    setIsLoading(true);
    findByAgenciaEventoCodigo(newPage, size).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListEventoDatas(data);
        }
      }
      setIsLoading(false);
    });
  };

  const fetchEventoDataCategoria = (newPage) => {
    setIsLoading(true);
    pageEventoDataCategoria(eventoData.codigo, newPage, size).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListEventoDataCategorias(data);
        }
      }
      setIsLoading(false);
    });
  };

  const handleEventoDataCategoriaChangePage = (event, newPage) => {
    fetchEventoDataCategoria(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
  };


  const handleGenerateNotaFinalPorCategoria = () => {
    setIsLoadingAction(true);
    generateNotaFinalPorCategoria(eventoDataCategoria.codigo).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          toast.success("Operação Realizada com Sucesso.");
        }
      }
      setIsLoadingAction(false);

    })
  }
  const handleGeneratePremioPorCategoria = () => {
    setIsLoadingAction(true);
    generateNPremioPorCategoria(eventoDataCategoria.codigo).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          toast.success("Operação Realizada com Sucesso.");
        }
      }
      setIsLoadingAction(false);

    })
  }

  const handleDelete = (codigo) => {
    confirmAlert({
      title: "Alerta",
      message: "Deseja deletar este evento data?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            setIsLoadingRemove(true);
            removeEventoDataCategoria(codigo).then((data) => {
              if (data.error) {
                errorMessage(data.error);
              } else {
                setListEventoDataCategorias((eventoElement) => ({
                  ...eventoElement,
                  content: eventoElement.content.filter(
                    (element) => element.codigo !== codigo
                  ),
                }));
                setEventoDataCategoria(null);
                toast.success("Registro excluído com Sucesso.");
              }
              setIsLoadingRemove(false);
            });
          },
        },
        {
          label: "Não",
          onClick: () => { },
        },
      ],
    });
  };

  const handleEventoDataChangeRowsPerPage = (event) => {
    setSize(event.target.value);
  };

  const handleTrocaEventoData = () => {
    setEventoData(null);
  };

  const handleSave = () => {
    const eNovo = eventoDataCategoria.eNovo;
    saveEventoDataCategoria(eventoDataCategoria).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          if (eNovo) {
            setListEventoDataCategorias((prevState) => ({
              ...prevState,
              content: [data, ...prevState.content],
            }));
          } else {
            setListEventoDataCategorias((prevState) => ({
              ...prevState,
              content: prevState.content.map((content) => {
                if (content.codigo === data.codigo) {
                  return data;
                } else {
                  return content;
                }
              }),
            }));
          }
          setEventoDataCategoria(data);
          toast.success("Operação Realizada com Sucesso.");
        }
      }
    });
  };

  const handleNovoRegistro = () => {
    setEventoDataCategoria({
      eNovo: true,
      eventoData: eventoData,
    });


  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {eventoData ? (
            <Grid container direction="column" spacing={2}>
              <Grid item sx={{ display: "flex" }}>
                <EventoDataProfile
                  eventoData={eventoData}
                  handleTrocaEventoData={handleTrocaEventoData}
                />
              </Grid>
              <Grid item>
                {eventoDataCategoria ? (
                  <EventoDataCategoriaForm
                    eventoDataCategoria={eventoDataCategoria}
                    setEventoDataCategoria={setEventoDataCategoria}
                    handleSave={handleSave}
                    isLoadingAction={isLoadingAction}
                    handleGenerateNotaFinalPorCategoria={handleGenerateNotaFinalPorCategoria}
                    handleGeneratePremiacaoPorCategoria={handleGeneratePremioPorCategoria}
                  />
                ) : (
                  <EventoDataCategoriaTable
                    listEventoDataCategorias={listEventoDataCategorias}
                    handleDelete={handleDelete}
                    isLoadingRemove={isLoadingRemove}
                    handleChangePage={handleEventoDataCategoriaChangePage}
                    isLoading={isLoading}
                    size={size}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handleNovoRegistro={handleNovoRegistro}
                    setEventoDataCategoria={setEventoDataCategoria}
                  />
                )}
              </Grid>
            </Grid>
          ) : (
            <EventoDataTableSelectView
              listEventoDatas={listEventoDatas}
              handleEventoDataChangeRowsPerPage={
                handleEventoDataChangeRowsPerPage
              }
              sizeEventoData={size}
              handleEventoDataChangePage={handleChangePageEventoData}
              setEventoData={setEventoData}
              isLoading={isLoading}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default EventoDataCategoriaContainer;
