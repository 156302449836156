import { SERVER_URL } from '../../constants';
import session from '../../SessionHandler/session-helper';
import { fetchGetAuth, fetchRemoveAuth, fetchPostAuthFile, fetchPutAuth, fetchPostAuth } from '../../util/FetchUtil';

const controller = 'usuario';

const page = (pageNumber) => {
  const url = SERVER_URL + controller + '?page=' + pageNumber;
  return fetchGetAuth(url);
}

const currentUser = () => {
  const url = SERVER_URL + controller + '/current-user';
  return fetchGetAuth(url);
}

const findAllPerfis = () => {
  const url = SERVER_URL + controller + '/perfis';
  return fetchGetAuth(url);
}

const findProfessoresAtivos = () => {
  const url = SERVER_URL + controller + '/professores-ativos';
  return fetchGetAuth(url);
}

const remove = (codUsuario) => {
  const url = SERVER_URL + controller + '/delete/' + codUsuario;
  return fetchRemoveAuth(url);
}

const save = (usuario) => {
  const url = SERVER_URL + controller;
  return fetchPostAuth(url, usuario);
}

export const saveCurrentUser = (usuario) => {
  const url = SERVER_URL + controller + "/current-user";
  return fetchPostAuth(url, usuario);
}

export const removeUserPhoto = (codUsuario) => {
  const url = SERVER_URL + controller + "/remove-photo/" + codUsuario;
  return fetchRemoveAuth(url);
}

const changePerfil = (perfil) => {
  const url = SERVER_URL + controller + '/change-perfil';
  return fetchPostAuth(url, perfil);
}

const changeARAluno = (aluno) => {
  const url = SERVER_URL + controller + '/change-ar-aluno';
  return fetchPostAuth(url, aluno);
}

const changeARAlunoAdmin = (codUsuario, codAluno) => {
  const url = SERVER_URL + controller + '/change-ar-aluno/' + codUsuario + '/' + codAluno;
  return fetchGetAuth(url);
}

const changePassword = (usuarioPassword) => {
  const url = SERVER_URL + controller + '/change-password';
  return fetchPutAuth(url, usuarioPassword);
}

const findByNome = (nome) => {
  const url = SERVER_URL + controller + '/search?nome=' + nome;
  return fetchGetAuth(url);
}

const findByEmail = (email) => {
  const url = SERVER_URL + controller + '/search?email=' + email;
  return fetchGetAuth(url);
}

const importFile = (fileUsers) => {
  const url = SERVER_URL + controller + '/import-file'
  return fetchPostAuthFile(url, fileUsers);
}

const importFileResponsaveis = (fileUsers) => {
  const url = SERVER_URL + controller + '/import-file-responsaveis'
  return fetchPostAuthFile(url, fileUsers);
}

const updateInstituicao = (codInstituicao) => {
  const url = SERVER_URL + controller + '/update-instituicao/' + codInstituicao;
  return fetchGetAuth(url);
}

const findByUsuarioRespNuncaAcessou = (anoLetivo) => {
  const url = SERVER_URL + controller + '/search?resp-nunca-acessou=true&ano-letivo=' + anoLetivo;
  return fetchGetAuth(url);
}

export const findApenasProfessoresAndAnoLetivo = () => {
  const url = SERVER_URL + controller + '/search?apenas-professores=true&ano-letivo=' + session.getAnoLetivo();
  return fetchGetAuth(url);
}

export const changePhoto = (codUsuario, formData) => {
  const url = SERVER_URL + controller + '/change-photo/' + codUsuario;
  return fetchPostAuthFile(url, formData);
}

export {
  findAllPerfis,
  page,
  save,
  remove,
  changePassword,
  currentUser,
  findProfessoresAtivos,
  findByNome,
  findByEmail,
  importFile,
  importFileResponsaveis,
  changePerfil,
  changeARAluno,
  changeARAlunoAdmin,
  updateInstituicao,
  findByUsuarioRespNuncaAcessou
}