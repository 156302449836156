import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import Button from "../../UI/Button";
import Loading from "../../UI/Loading";
import Title from "../../UI/Title";
import { dateTimeFormat } from "../../../util/DateUtil";

const EventoDataTableSelectView = ({
  listEventoDatas,
  handleEventoDataChangeRowsPerPage,
  sizeEventoData,
  handleEventoDataChangePage,
  setEventoData,
  isLoading,
}) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Title>Selecione um Evento Data</Title>
      </Grid>
      <TablePagination
        component="div"
        count={listEventoDatas.totalElements}
        page={listEventoDatas.number ? listEventoDatas.number : 0}
        onPageChange={handleEventoDataChangePage}
        rowsPerPage={sizeEventoData}
        onRowsPerPageChange={handleEventoDataChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Descrição</TableCell>
            <TableCell>País</TableCell>
            <TableCell>Data Hora Evento</TableCell>
            <TableCell>Máximo Coreografias</TableCell>
            <TableCell>Máximo Participantes</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <Loading description="Carregando Evento Datas..." />
          ) : (
            <>
              {listEventoDatas.content.length === 0 ? (
                <Typography sx={{ py: 3 }}>Nenhum evento cadastrado</Typography>
              ) : (
                <>
                  {listEventoDatas.content.map((eventoData) => (
                    <TableRow>
                      <TableCell>
                        {eventoData.evento.descricao
                          ? eventoData.evento.descricao
                          : ""}
                      </TableCell>
                      <TableCell>
                        {eventoData.evento.pais ? eventoData.evento.pais : ""}
                      </TableCell>
                      <TableCell>
                        {eventoData.dataHoraEvento
                          ? dateTimeFormat(eventoData.dataHoraEvento)
                          : ""}
                      </TableCell>
                      <TableCell>
                        {eventoData.maximoCoreografias
                          ? eventoData.maximoCoreografias
                          : ""}
                      </TableCell>
                      <TableCell>
                        {eventoData.maximoParticipantes
                          ? eventoData.maximoParticipantes
                          : ""}
                      </TableCell>

                      <TableCell align="right">
                        <Button
                          variant="outlined"
                          size="small"
                          color="secondary"
                          onClick={() => setEventoData(eventoData)}
                        >
                          Selecionar
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={
          listEventoDatas.totalElements ? listEventoDatas.totalElements : 0
        }
        page={listEventoDatas.number ? listEventoDatas.number : 0}
        onPageChange={handleEventoDataChangePage}
        rowsPerPage={sizeEventoData}
        onRowsPerPageChange={handleEventoDataChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
    </React.Fragment>
  );
};

export default EventoDataTableSelectView;
