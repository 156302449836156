import { SERVER_URL } from '../../constants';
import { fetchGetAuth, fetchPostAuth, fetchRemoveAuthReturn } from '../../util/FetchUtil';

const controller = 'indicacao';

export const page = (pageNumber, size) => {
  const url = SERVER_URL + controller + '/query-page?page=' + pageNumber + '&size=' + size;
  return fetchGetAuth(url);
}

export const findByDescricao = (descricao, pageNumber, size) => {
  const url = SERVER_URL + controller + '/query-page?descricao=' + descricao + '&page=' + pageNumber + '&size=' + size;
  return fetchGetAuth(url);
}

export const remove = (codigo) => {
  const url = SERVER_URL + controller + "/" + codigo;
  return fetchRemoveAuthReturn(url);
}

export const saveIndicacao = (indicacao) => {
  const url = SERVER_URL + controller + "/save";
  return fetchPostAuth(url, indicacao);
}

