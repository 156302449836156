import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import auth from "./auth-helper";
import { useDispatch } from "react-redux";
import { setLogout } from "./actions/actions";
import { signin } from "./api-auth";
import { errorMessage } from "../components/util/ConfirmAlertUtil";
import { currentUser } from "../components/Usuario/api-usuario";
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import LogoImg from "../shared/assets/img/logo.jpeg";
import Copyright from "../components/Copyright";
import Button from "../components/UI/Button";

const Signin = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState({
    username: "",
    password: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Sign In";
  }, []);

  useEffect(() => {
    const jwt = auth.isAuthenticated();
    if (jwt) {
      setAuthenticated(true);
      currentUser().then((data) => {
        if (data) {
          if (data.error) {
            errorMessage(data.message);
          } else {
            dispatch(setUser(data));
          }
        }
      });
    }
  }, [dispatch]);

  const handleChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  const clickSubmit = (event) => {
    event.preventDefault();

    if (!user.username || !user.password) {
      toast.error("Preencha usuário/password");
      return;
    }
    signin(user)
      .then((response) => {
        if (response.ok) {
          dispatch(setLogout(false));
          const jwtToken = response.headers.get("Authorization");
          localStorage.setItem("token", jwtToken);
          setAuthenticated(true);
        } else {
          if (response.status === 403) {
            toast.error("Acesso Negado");
          } else {
            toast.error("Erro");
          }
        }
      })
      .catch((err) => {
        if (err.message === "Failed to fetch") {
          toast.error("Ocorreu um erro. Tente novamente mais tarde.");
        }
        console.log(err);
      });
  };

  if (authenticated) {
    return <Navigate to="/home" />;
  } else {
    return (
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={LogoImg} alt="Logo" />
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={clickSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              onChange={handleChange}
              id="email"
              label="Usuário"
              name="username"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              onChange={handleChange}
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Lembrar usuário"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Button
                  component={Link}
                  to={{
                    pathname: '/request-reset-password'
                  }}
                  color="secondary">Esqueceu a senha?
                </Button>
              </Grid>
              <Grid item>
                <Button
                  component={Link}
                  to={{
                    pathname: '/novo-cadastro'
                  }}
                  color="secondary">{"Novo Cadastro"}</Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright />
      </Container>
    );
  }
};

export default Signin;
