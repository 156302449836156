import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { errorMessage } from "../../util/ConfirmAlertUtil";
import {
  findByEventoDataCodigo,
} from "../api-apresentacao";
import EventoDataProfile from "../../Evento/EventoData/EventoDataProfile";
import ApresentacaoCockpit from "../ApresentacaoCockpit";
import { findByJurado } from "../../Evento/EventoData/EventoDataJurado/api-evento-data-jurado";
import EventoDataJuradoTableSelectView from "../../Evento/EventoData/EventoDataJurado/EventoDataJuradoTableSelectView";

const ApresentacaoJuradoContainer = () => {
  const [listApresentacoes, setListApresentacoes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [listEventoDataJurados, setListEventoDataJurados] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [eventoDataJurado, setEventoDataJurado] = useState(null);
  const [size, setSize] = useState(10);

  useEffect(() => {
    fetchEventoData(0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (eventoDataJurado) {
      fetchApresentacoes();
    }
    // eslint-disable-next-line
  }, [eventoDataJurado]);

  const fetchEventoData = (pageNumber) => {
    setIsLoading(true);
    findByJurado(pageNumber, size).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListEventoDataJurados(data);
        }
      }
      setIsLoading(false);
    });
  };


  const handleEventoDataJuradoChangePage = (event, newPage) => {
    fetchEventoData(newPage);
  };

  const fetchApresentacoes = () => {
    setIsLoading(true);
    findByEventoDataCodigo(eventoDataJurado.eventoData.codigo).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListApresentacoes(data);
        }
      }
      setIsLoading(false);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
  };



  const handleTrocaEventoData = () => {
    setEventoDataJurado(null);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <>
            {eventoDataJurado ? (
              <>
                <Grid container direction="column" spacing={2}>
                  <Grid item sx={{ display: "flex" }}>
                    <EventoDataProfile
                      eventoData={eventoDataJurado.eventoData}
                      handleTrocaEventoData={handleTrocaEventoData}
                    />
                  </Grid>
                  <Grid item>
                    <ApresentacaoCockpit
                      listApresentacoes={listApresentacoes}
                      isLoading={isLoading}
                    />
                  </Grid>

                </Grid>
              </>
            ) : (
              <>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <EventoDataJuradoTableSelectView
                      listEventoDataJurados={listEventoDataJurados}
                      handleEventoDataJuradoChangePage={
                        handleEventoDataJuradoChangePage
                      }
                      isLoading={isLoading}
                      size={size}
                      handleChangeRowsPerPage={
                        handleChangeRowsPerPage
                      }
                      setEventoDataJurado={setEventoDataJurado}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </>

        </Paper>
      </Grid>
    </Grid>
  );
};

export default ApresentacaoJuradoContainer;
