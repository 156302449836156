import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import Copyright from "../../../components/Copyright";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Container,
  Divider,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Toolbar from "@mui/material/Toolbar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuUser from "./MenuUser";
import { currentUser } from "../../../components/Usuario/api-usuario";
import { errorMessage } from "../../../components/util/ConfirmAlertUtil";
import { useDispatch, useSelector } from "react-redux";
import { setUsuario } from "../../../components/Usuario/actions/actions";
import { Link } from "react-router-dom";
import LateralMenuList from "./LateralMenuList";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: 0,
    }),
  },
}));

const LateralMenuSkeleton2 = (props) => {
  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [anchorElUserMenu, setAnchorElUserMenu] = useState(null);
  const { children } = props;
  const dispatch = useDispatch();
  const usuario = useSelector((state) => state.user);

  const handleUserMenuIconClick = (event) => {
    setAnchorElUserMenu(event.currentTarget);
  };

  useEffect(() => {
    if (!usuario) {
      currentUser().then((data) => {
        if (data) {
          if (data.error) {
            errorMessage(data.message);
          } else {
            dispatch(setUsuario(data));
          }
        }
      });
    }
  }, [dispatch, usuario]);

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
              displayPrint: 'none'
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              <Link to="/">
                Mais Festival
              </Link>
            </Typography>
            <IconButton color="inherit">
              <Badge badgeContent={0} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton color="inherit" onClick={handleUserMenuIconClick}>
              <AccountCircleIcon />
            </IconButton>
            <MenuUser
              anchorElUserMenu={anchorElUserMenu}
              setAnchorElUserMenu={setAnchorElUserMenu}
            />
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          open={open}
          sx={{
            displayPrint: 'none'
          }}
        >

          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <Link to="/">
              <img
                style={{
                  width: "80px",
                }}
                src={usuario && usuario.instituicao && usuario.instituicao.urlLogo ? usuario.instituicao.urlLogo :
                  usuario && usuario.agenciaEvento && usuario.agenciaEvento.urlLogo ? usuario.agenciaEvento.urlLogo : null
                }
                alt=""
              />
            </Link>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />


          {usuario
            ? LateralMenuList.map((lateralMenu) => {
              const access = lateralMenu.access.find(
                (lm) => lm === usuario.perfil
              );
              if (access) {
                return (
                  <Link to={lateralMenu.link}>
                    <ListItemButton>
                      <ListItemText primary={lateralMenu.descricao} />
                    </ListItemButton>
                  </Link>
                );
              } else {
                return "";
              }
            })
            : ""}
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            {children}
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </div>
  );
};

export default LateralMenuSkeleton2;
