import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import Button from "../UI/Button";
import Loading from "../UI/Loading";
import Title from "../UI/Title";
import { dateTimeFormat } from "../../util/DateUtil";

const CoreografiaTableSelectView = ({
  listCoreografias,
  handleChangeRowsPerPage,
  size,
  handleCoreografiaChangePage,
  setCoreografia,
  isLoading,
}) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Title>Selecione uma Coreografia</Title>
      </Grid>
      <TablePagination
        component="div"
        count={listCoreografias.totalElements}
        page={listCoreografias.number ? listCoreografias.number : 0}
        onPageChange={handleCoreografiaChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Evento Data Hora</TableCell>
            <TableCell>Categoria</TableCell>
            <TableCell>Coreografia</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <Loading description="Carregando Coreografias..." />
          ) : (
            <>
              {listCoreografias.content.length === 0 ? (
                <Typography sx={{ py: 3 }}>
                  Nenhuma Coreografia cadastrado
                </Typography>
              ) : (
                <>
                  {listCoreografias.content.map((coreografia) => (
                    <TableRow>
                      <TableCell>
                        {coreografia.eventoDataCategoria.eventoData
                          ? dateTimeFormat(
                              coreografia.eventoDataCategoria.eventoData
                                .dataHoraEvento
                            )
                          : ""}
                      </TableCell>
                      <TableCell>
                        {coreografia.eventoDataCategoria
                          ? coreografia.eventoDataCategoria.categoria
                              .descricao
                          : ""}
                      </TableCell>
                      <TableCell>
                        {coreografia.descricao ? coreografia.descricao : ""}
                      </TableCell>

                      <TableCell align="right">
                        <Button
                          variant="outlined"
                          size="small"
                          color="secondary"
                          onClick={() => setCoreografia(coreografia)}
                        >
                          Selecionar
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={
          listCoreografias.totalElements ? listCoreografias.totalElements : 0
        }
        page={listCoreografias.number ? listCoreografias.number : 0}
        onPageChange={handleCoreografiaChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
    </React.Fragment>
  );
};

export default CoreografiaTableSelectView;
