import { SERVER_URL } from '../../../../constants';
import { fetchGetAuth, fetchPostAuth, fetchRemoveAuthReturn } from '../../../../util/FetchUtil';

const controller = 'evento-data-jurado';

export const page = (codEventoData, pageNumber, size) => {
  const url = SERVER_URL + controller +
    '/query-page?page=' + pageNumber + '&size=' + size + '&cod-evento-data=' + codEventoData;
  return fetchGetAuth(url);
}

export const removeEventoDataJurado = (codigo) => {
  const url = SERVER_URL + controller + "/" + codigo;
  return fetchRemoveAuthReturn(url);
}

export const saveEventoDataJurado = (eventoDataJurado) => {
  const url = SERVER_URL + controller + "/save";
  return fetchPostAuth(url, eventoDataJurado);
}

export const findByAgenciaEventoCodigo = (pageNumber, size) => {
  const url = SERVER_URL + controller +
    '/query-page?page=' + pageNumber + '&size=' + size;
  return fetchGetAuth(url);
}

export const findByEventoCodigo = (codEvento, pageNumber, size) => {
  const url = SERVER_URL + controller +
    '/query-page?page=' + pageNumber + '&size=' + size + '&cod-evento=' + codEvento;
  return fetchGetAuth(url);
}
export const findByJurado = (pageNumber, size) => {
  const url = SERVER_URL + controller +
    '/query-page?page=' + pageNumber + '&size=' + size + '&is-jurado=true';
  return fetchGetAuth(url);
}