import { Box, Container, Divider, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import LogoImg from "../../shared/assets/img/logo.jpeg";
import Button from '../UI/Button';
import Copyright from '../Copyright';
import { create } from './api-instituicao';
import { errorMessage } from '../util/ConfirmAlertUtil';
import { useNavigate } from 'react-router-dom';

const InstituicaoFormCreate = () => {
    const [instituicao, setInstituicao] = useState(null);
    const [instituicaoUsuarioCreate, setInstituicaoUsuarioCreate] = useState(null);
    const [message, setMessage] = useState(null);
    const [isLoadingAction, setIsLoadingAction] = useState(false)
    const navigate = useNavigate();

    const handleInstituicaoUsuarioCreateOnChange = (event) => {
        setInstituicaoUsuarioCreate((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    };

    const handleInstituicaoOnChange = (event) => {
        setInstituicao((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    };


    const handleCreate = () => {
        setIsLoadingAction(true);
        const persist = {
            instituicao: instituicao,
            ...instituicaoUsuarioCreate
        }
        create(persist).then((data) => {
            if (data) {
                if (!data.ok) {
                    errorMessage("Email já utilizado");
                } else {
                    setMessage("Cadastro Realizado com Sucesso. Verifique o email cadastrado para obter as chaves de acesso a Plataforma.");
                }
            }
            setIsLoadingAction(false);
        })
    }

    return (
        <Container component="main" fullWidth>
            <Paper sx={{ p: 2, mt: 2 }}>
                <Box
                    sx={{
                        marginTop: 3
                    }}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <img src={LogoImg} alt="Logo" />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component="h1" variant="h5">
                                Novo Cadastro Instituição
                            </Typography>
                        </Grid>
                        {message ?
                            <>
                                <Grid item xs={12}>
                                    <Typography style={{
                                        border: '1px solid darkgreen',
                                        padding: '30px',
                                        borderRadius: '30px',
                                        backgroundColor: 'palegreen',
                                    }} >
                                        {message}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Button

                                        variant="contained"
                                        color="primary"
                                        onClick={() => navigate('/')}
                                    >
                                        Voltar para Login
                                    </Button>
                                </Grid>
                            </> :
                            <>
                                <Grid item xs={12}>
                                    <Box
                                        component="form"
                                        noValidate
                                        sx={{ mt: 1 }}
                                    >
                                        <Grid container direction='row' spacing={3}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    value={instituicao &&
                                                        instituicao.descricao ?
                                                        instituicao.descricao : ''}
                                                    onChange={handleInstituicaoOnChange}
                                                    label="Nome Instituição"
                                                    name="descricao"
                                                    type='name'
                                                    autoFocus
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    value={instituicao &&
                                                        instituicao.cpfCnpj ?
                                                        instituicao.cpfCnpj : ''}
                                                    onChange={handleInstituicaoOnChange}
                                                    label="CPF/CNPJ"
                                                    name="cpfCnpj"
                                                    type='number'
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    value={instituicao &&
                                                        instituicao.telefone ?
                                                        instituicao.telefone : ''}
                                                    onChange={handleInstituicaoOnChange}
                                                    label="Telefone"
                                                    name="telefone"
                                                    type='number'
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    value={instituicao &&
                                                        instituicao.celular ?
                                                        instituicao.celular : ''}
                                                    onChange={handleInstituicaoOnChange}
                                                    label="Celular"
                                                    name="celular"
                                                    type='number'
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    value={instituicao &&
                                                        instituicao.whatsApp ?
                                                        instituicao.whatsApp : ''}
                                                    onChange={handleInstituicaoOnChange}
                                                    label="WhatsApp"
                                                    name="whatsApp"
                                                    type='number'
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    value={instituicao &&
                                                        instituicao.urlYoutube ?
                                                        instituicao.urlYoutube : ''}
                                                    onChange={handleInstituicaoOnChange}
                                                    label="Url Youtube"
                                                    name="urlYoutube"
                                                    type='name'
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    value={instituicao &&
                                                        instituicao.urlFacebook ?
                                                        instituicao.urlFacebook : ''}
                                                    onChange={handleInstituicaoOnChange}
                                                    label="Url Facebook"
                                                    name="urlFacebook"
                                                    type='name'
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    value={instituicao &&
                                                        instituicao.urlInstagram ?
                                                        instituicao.urlInstagram : ''}
                                                    onChange={handleInstituicaoOnChange}
                                                    label="Url Instagram"
                                                    name="urlInstagram"
                                                    type='name'
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    value={instituicao &&
                                                        instituicao.endereco ?
                                                        instituicao.endereco : ''}
                                                    onChange={handleInstituicaoOnChange}
                                                    label="Endereço"
                                                    name="endereco"
                                                    type='name'
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    value={instituicao &&
                                                        instituicao.numero ?
                                                        instituicao.numero : ''}
                                                    onChange={handleInstituicaoOnChange}
                                                    label="Número"
                                                    name="numero"
                                                    type='name'
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    value={instituicao &&
                                                        instituicao.complemento ?
                                                        instituicao.complemento : ''}
                                                    onChange={handleInstituicaoOnChange}
                                                    label="Complemento"
                                                    name="complemento"
                                                    type='name'
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    value={instituicao &&
                                                        instituicao.bairro ?
                                                        instituicao.bairro : ''}
                                                    onChange={handleInstituicaoOnChange}
                                                    label="Bairro"
                                                    name="bairro"
                                                    type='name'
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    value={instituicao &&
                                                        instituicao.cep ?
                                                        instituicao.cep : ''}
                                                    onChange={handleInstituicaoOnChange}
                                                    label="CEP"
                                                    name="cep"
                                                    type='number'
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    value={instituicao &&
                                                        instituicao.cidade ?
                                                        instituicao.cidade : ''}
                                                    onChange={handleInstituicaoOnChange}
                                                    label="Cidade"
                                                    name="cidade"
                                                    type='name'
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    value={instituicao &&
                                                        instituicao.estado ?
                                                        instituicao.estado : ''}
                                                    onChange={handleInstituicaoOnChange}
                                                    label="Estado"
                                                    name="estado"
                                                    type='name'
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography component="h1" variant="h5">
                                                    Usuário Acesso
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    onChange={handleInstituicaoUsuarioCreateOnChange}
                                                    label="Nome Usuário"
                                                    name="nomeUsuario"
                                                    required
                                                    type='name'
                                                />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    onChange={handleInstituicaoUsuarioCreateOnChange}
                                                    label="E-mail Usuário"
                                                    name="emailUsuario"
                                                    type='email'
                                                    required
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Button
                                                    isLoadingAction={isLoadingAction}
                                                    onClick={handleCreate}
                                                    variant="contained"
                                                    sx={{ mt: 3, mb: 2 }}
                                                >
                                                    Salvar
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </>
                        }

                    </Grid>
                </Box>
            </Paper>
            <Copyright />
        </Container>

    )
}

export default InstituicaoFormCreate;