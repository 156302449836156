import React from 'react';
import { Button as ButtonMaterial, CircularProgress } from '@mui/material';

const Button = (props) => {
    return (
        <ButtonMaterial
            variant="contained"
            color="primary"
            disabled={props.isLoadingAction}
            {...props}
        >
            {props.isLoadingAction && <CircularProgress color="secondary" size={20} sx={{ mr: 2 }} />}
            {props.children}
        </ButtonMaterial>
    )
}

export default Button;