import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { errorMessage } from "../../../util/ConfirmAlertUtil";
import { removeEventoDataJurado, saveEventoDataJurado } from "./api-evento-data-jurado";
import { page as pageEvento } from "../../api-evento";
import { page as pageEventoData } from "../api-evento-data";
import { confirmAlert } from "react-confirm-alert";
import EventoTableSelectView from "../../EventoTableSelectView";
import EventoProfile from "../../EventoProfile";
import EventoDataTableSelectView from "../EventoDataTableSelectView";
import EventoDataProfile from "../EventoDataProfile";
import EventoDataJuradoTable from "./EventoDataJuradoTable";
import EventoDataJuradoForm from "./EventoDataJuradoForm";
import { page as pageEventoDataJurado } from "./api-evento-data-jurado";

const EventoDataJuradoContainer = () => {
  const [listEventos, setListEventos] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [evento, setEvento] = useState(null);
  const [listEventoDatas, setListEventoDatas] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [eventoData, setEventoData] = useState(null);
  const [listEventoDataJurado, setListEventoDataJurado] = useState(
    {
      content: [],
      size: 0,
      number: 0,
      totalElements: 0,
    }
  );
  const [eventoDataJurado, setEventoDataJurado] = useState(null);
  const [isLoadingRemove, setIsLoadingRemove] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [size, setSize] = useState(10);

  useEffect(() => {
    fetchDataEvento(0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (evento) {
      fetchDataEventoData(0);
    }
    // eslint-disable-next-line
  }, [evento]);

  useEffect(() => {
    if (eventoData) {
      fetchDataEventoDataJurado(0);
    }
    // eslint-disable-next-line
  }, [eventoData]);

  const fetchDataEventoData = (newPage) => {
    setIsLoading(true);
    pageEventoData(evento.codigo, newPage, size).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListEventoDatas(data);
        }
      }
      setIsLoading(false);
    });
  };

  const fetchDataEventoDataJurado = (newPage) => {
    setIsLoading(true);
    pageEventoDataJurado(eventoData.codigo, newPage, size).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListEventoDataJurado(data);
        }
      }
      setIsLoading(false);
    });
  };

  const handleEventoDataJuradoChangePage = (event, newPage) => {
    fetchDataEventoDataJurado(newPage);
  }

  const handleEventoDataChangePage = (event, newPage) => {
    if (evento) {
      fetchDataEventoData(newPage);
    }
  };



  const fetchDataEvento = (newPage) => {
    setIsLoading(true);
    pageEvento(newPage, size).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListEventos(data);
        }
      }
      setIsLoading(false);
    });
  };

  const handleEventoChangePage = (event, newPage) => {
    fetchDataEvento(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
  };

  const handleDelete = (codigo) => {
    confirmAlert({
      title: "Alerta",
      message: "Deseja remover este jurado?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            setIsLoadingRemove(true);
            removeEventoDataJurado(codigo).then((data) => {
              if (data.error) {
                errorMessage(data.message);
              } else {
                setListEventoDataJurado((eventoDataJuradoElement) => ({
                  ...eventoDataJuradoElement,
                  content: eventoDataJuradoElement.content.filter(
                    (element) => element.codigo !== codigo
                  )
                }));
                setEventoDataJurado(null);
                toast.success("Registro excluído com Sucesso.");
              }
              setIsLoadingRemove(false);
            });
          },
        },
        {
          label: "Não",
          onClick: () => { },
        },
      ],
    });
  };

  const handleSave = () => {
    if (eventoDataJurado.usuario === null) {
      errorMessage("Selecione um Jurado.");
    }
    const eNovo = eventoDataJurado.eNovo;
    saveEventoDataJurado(eventoDataJurado).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          if (eNovo) {
            setListEventoDataJurado((prevState) => ({
              ...prevState,
              content: [data, ...prevState.content]
            }));
          } else {
            setListEventoDataJurado((prevState) => ({
              ...prevState,
              content: prevState.content.map((content) => {
                if (content.codigo === data.codigo) {
                  return data;
                } else {
                  return content;
                }
              })
            }));
          }
          setEventoDataJurado(data);
          toast.success("Operação Realizada com Sucesso.");
        }
      }
    });
  };

  const handleEventoChangeRowsPerPage = (event) => {
    setSize(event.target.value);
  };

  const handleTrocaEvento = () => {
    setEvento(null);
  };

  const handleTrocaEventoData = () => {
    setEventoData(null);
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {evento ? (
            <Grid container direction="column" spacing={2}>
              <Grid item>
                {eventoData ? (
                  <>
                    {eventoDataJurado ?
                      <>
                        <EventoDataJuradoForm
                          eventoDataJurado={eventoDataJurado}
                          setEventoDataJurado={setEventoDataJurado}
                          handleSave={handleSave}
                        />
                      </> :
                      <>
                        <EventoDataProfile
                          eventoData={eventoData}
                          handleTrocaEventoData={handleTrocaEventoData}
                        />
                        <EventoDataJuradoTable
                          listEventoDataJurado={listEventoDataJurado}
                          handleDelete={handleDelete}
                          isLoadingRemove={isLoadingRemove}
                          setEventoDataJurado={setEventoDataJurado}
                          handleChangePage={handleEventoDataJuradoChangePage}
                          isLoading={isLoading}
                          size={size}
                          handleChangeRowsPerPage={handleChangeRowsPerPage}
                          eventoData={eventoData}
                        /></>}

                  </>
                ) : (
                  <>
                    <Grid item sx={{ display: "flex" }}>
                      <EventoProfile
                        evento={evento}
                        handleTrocaEvento={handleTrocaEvento}
                      />
                    </Grid>
                    <EventoDataTableSelectView
                      listEventoDatas={listEventoDatas}
                      setEventoData={setEventoData}
                      handleEventoDataChangePage={handleEventoDataChangePage}
                      isLoading={isLoading}
                      sizeEventoData={size}
                      handleEventoDataChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </>
                )}
              </Grid>
            </Grid>
          ) : (
            <EventoTableSelectView
              listEventos={listEventos}
              handleEventoChangeRowsPerPage={handleEventoChangeRowsPerPage}
              sizeEvento={size}
              handleEventoChangePage={handleEventoChangePage}
              setEvento={setEvento}
              isLoadingEvento={isLoading}
            />
          )}
        </Paper>
      </Grid >
    </Grid >
  );
};

export default EventoDataJuradoContainer;
