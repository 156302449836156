import { SERVER_URL } from '../../constants';
import { fetchPostAuthFile, fetchRemoveAuthReturn } from '../../util/FetchUtil';

const controller = 'instituicao';

export const create = (insituicaoUsuarioCreate) => {
  const url = SERVER_URL + controller + '/create';
  return fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify(insituicaoUsuarioCreate)
  }).then((response) => {
    return response;
  });

}

export const removeUploadedLogo = () => {
  const url = SERVER_URL + controller + "/remove-uploaded-logo";
  return fetchRemoveAuthReturn(url);
}

export const uploadLogo = (formData) => {
  const url = SERVER_URL + controller + '/upload-logo';
  return fetchPostAuthFile(url, formData);
}

