import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import Button from "../UI/Button";
import Loading from "../UI/Loading";
import Title from "../UI/Title";
import { dateTimeFormat } from "../../util/DateUtil";

const ApresentacaoTableSelectView = ({
  handleSelected,
  listApresentacoes,
  isLoading,
}) => {
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Title>Selecione uma apresentação</Title>
        </Grid>

        <Grid item>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Categoria</TableCell>
                <TableCell>Instituição</TableCell>
                <TableCell>Coreografia</TableCell>
                <TableCell>Nome Música</TableCell>
                <TableCell>Nome Compositor</TableCell>
                <TableCell>Tipo Coreografia</TableCell>
                <TableCell>Início</TableCell>
                <TableCell>Fim</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <Loading description="Carregando apresentações..." />
              ) : (
                <>
                  {listApresentacoes.length === 0 ? (
                    <Typography sx={{ py: 3 }}>
                      Nenhuma apresentação cadastrada
                    </Typography>
                  ) : (
                    <>
                      {listApresentacoes.map((a) => (
                        <TableRow>
                          <TableCell>
                            {a.coreografia &&
                            a.coreografia.eventoDataCategoria.categoria
                              .descricao
                              ? a.coreografia.eventoDataCategoria.categoria
                                  .descricao
                              : "Intervalo"}
                          </TableCell>
                          <TableCell>
                            {a.coreografia &&
                            a.coreografia.instituicao.descricao
                              ? a.coreografia.instituicao.descricao
                              : ""}
                          </TableCell>
                          <TableCell>
                            {a.coreografia && a.coreografia.descricao
                              ? a.coreografia.descricao
                              : ""}
                          </TableCell>

                          <TableCell>
                            {a.coreografia && a.coreografia.nomeMusica
                              ? a.coreografia.nomeMusica
                              : ""}
                          </TableCell>
                          <TableCell>
                            {a.coreografia && a.coreografia.nomeCompositor
                              ? a.coreografia.nomeCompositor
                              : ""}
                          </TableCell>
                          <TableCell>
                            {a.coreografia && a.coreografia.coreografiaTipo
                              ? a.coreografia.coreografiaTipo
                              : ""}
                          </TableCell>
                          <TableCell>
                            {a.dataHoraInicio
                              ? dateTimeFormat(a.dataHoraInicio)
                              : ""}
                          </TableCell>
                          <TableCell>
                            {a.dataHoraFim ? dateTimeFormat(a.dataHoraFim) : ""}
                          </TableCell>
                          <TableCell>
                            <Button onClick={() => handleSelected(a)}>
                              Selecionar
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ApresentacaoTableSelectView;
