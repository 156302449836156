import { Dialog, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { errorMessage } from "../../util/ConfirmAlertUtil";
import UsuarioPerfilTableSelectView from "./UsuarioPerfilTableSelectView";
import { findByPerfil } from "./api-usuario-perfil";
import { JURADO } from "../Perfil";

const UsuarioPerfilJuradoFormSearchDialog = ({ setUsuarioPerfil, openDialog, handleCloseDialog }) => {
  const [listJurados, setListJurados] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [size, setSize] = useState(10);

  useEffect(() => {
    fetchJurado(0);
    // eslint-disable-next-line
  }, []);

  const handleJuradoChangePage = (newPage) => {
    fetchJurado(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
  };

  const fetchJurado = (newPage) => {
    setIsLoading(true);
    findByPerfil(JURADO, newPage, size).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListJurados(data);
        }
      }
      setIsLoading(false);
    });
  };

  return (
    <Dialog onClose={handleCloseDialog} open={openDialog}>
      <Grid container spacing={3} sx={{ p: 3 }}>
        <Grid item xs={12}>
          <UsuarioPerfilTableSelectView
            listUsuarioPerfil={listJurados}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            size={size}
            handleUsuarioPerfilChangePage={handleJuradoChangePage}
            setUsuarioPerfil={setUsuarioPerfil}
            isLoading={isLoading}


          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default UsuarioPerfilJuradoFormSearchDialog;
