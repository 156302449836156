import { SERVER_URL } from '../../constants';
import { fetchGetAuth, fetchPostAuth, fetchPostAuthFile, fetchRemoveAuthReturn } from '../../util/FetchUtil';

const controller = 'participante';

export const page = (pageNumber, size) => {
  const url = SERVER_URL + controller + '/query-page?page=' + pageNumber + '&size=' + size;
  return fetchGetAuth(url);
}

export const remove = (codigo) => {
  const url = SERVER_URL + controller + "/" + codigo;
  return fetchRemoveAuthReturn(url);
}

export const removeUploadedDocument = (codigo) => {
  const url = SERVER_URL + controller + "/remove-uploaded-document/" + codigo;
  return fetchRemoveAuthReturn(url);
}

export const saveParticipante = (participante) => {
  const url = SERVER_URL + controller + "/save";
  return fetchPostAuth(url, participante);
}

export const uploadDocument = (formData, codParticipante) => {
  const url = SERVER_URL + controller + '/upload-document/' + codParticipante;
  return fetchPostAuthFile(url, formData);
}