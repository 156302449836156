import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import Loading from "../UI/Loading";
import Title from "../UI/Title";
import { dateFormat } from "../../util/DateUtil";
import { currencyRound } from "../../util/DoubleUtil";
import { useSelector } from "react-redux";
import { DIRETOR_EVENTO } from "../Usuario/Perfil";
import Button from "../UI/Button";

const CoreografiaPagamentoTable = ({
  listCoreografiaPagamentos,
  handleChangePage,
  isLoading,
  size,
  handleChangeRowsPerPage,
  setCoreografiaPagamento,
}) => {
  const usuario = useSelector((state) => state.user);
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Title>Coreografia Pagamento</Title>
      </Grid>
      <TablePagination
        component="div"
        count={listCoreografiaPagamentos.totalElements}
        page={
          listCoreografiaPagamentos.number
            ? listCoreografiaPagamentos.number
            : 0
        }
        onPageChange={handleChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Descrição</TableCell>
            <TableCell>Data Emissão</TableCell>
            <TableCell>Valor</TableCell>
            <TableCell>Data Pagamento</TableCell>
            <TableCell>Valor Recebido</TableCell>
            <TableCell>Data Limite Desconto</TableCell>
            <TableCell>Porcentagem Desconto</TableCell>
            {usuario.perfil === DIRETOR_EVENTO && (
              <TableCell>Selecionar</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <Loading description="Carregando pagamentos..." />
          ) : (
            <>
              {listCoreografiaPagamentos.content.length === 0 ? (
                <Typography sx={{ py: 3 }}>
                  Nenhum pagamento cadastrado
                </Typography>
              ) : (
                <>
                  {listCoreografiaPagamentos.content.map(
                    (coreografiaPagamento) => (
                      <TableRow>
                        <TableCell>
                          {coreografiaPagamento.descricao
                            ? coreografiaPagamento.descricao
                            : ""}
                        </TableCell>
                        <TableCell>
                          {coreografiaPagamento.dataEmissao
                            ? dateFormat(coreografiaPagamento.dataEmissao)
                            : ""}
                        </TableCell>
                        <TableCell>
                          {coreografiaPagamento.valor
                            ? currencyRound(coreografiaPagamento.valor)
                            : ""}
                        </TableCell>
                        <TableCell>
                          {coreografiaPagamento.dataPagamento
                            ? dateFormat(coreografiaPagamento.dataPagamento)
                            : ""}
                        </TableCell>
                        <TableCell>
                          {coreografiaPagamento.valorRecebido
                            ? currencyRound(coreografiaPagamento.valorRecebido)
                            : ""}
                        </TableCell>
                        <TableCell>
                          {coreografiaPagamento.dataLimiteDesconto
                            ? dateFormat(coreografiaPagamento.dataLimiteDesconto)
                            : ""}
                        </TableCell>
                        <TableCell>
                          {coreografiaPagamento.porcentagemDesconto
                            ? currencyRound(coreografiaPagamento.porcentagemDesconto) + " %"
                            : ""}
                        </TableCell>
                        {usuario.perfil === DIRETOR_EVENTO && (
                          <TableCell>
                            <Button
                              onClick={() =>
                                setCoreografiaPagamento(coreografiaPagamento)
                              }
                            >
                              Selecionar
                            </Button>
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  )}
                </>
              )}
            </>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={
          listCoreografiaPagamentos.totalElements
            ? listCoreografiaPagamentos.totalElements
            : 0
        }
        page={
          listCoreografiaPagamentos.number
            ? listCoreografiaPagamentos.number
            : 0
        }
        onPageChange={handleChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
    </React.Fragment>
  );
};

export default CoreografiaPagamentoTable;
