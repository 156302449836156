import { Avatar, Card, CardHeader, CardMedia } from '@mui/material';
import React from 'react';
import Model_1 from "../../shared/assets/img/evento/banner-model/model_1.jpg";
import Model_2 from "../../shared/assets/img/evento/banner-model/model_2.jpg";
import Model_3 from "../../shared/assets/img/evento/banner-model/model_3.jpg";
import Model_4 from "../../shared/assets/img/evento/banner-model/model_4.jpg";
import Model_5 from "../../shared/assets/img/evento/banner-model/model_5.jpg";
import Model_6 from "../../shared/assets/img/evento/banner-model/model_6.jpg";
import Model_7 from "../../shared/assets/img/evento/banner-model/model_7.jpg";
import Model_8 from "../../shared/assets/img/evento/banner-model/model_8.jpeg";
import { dateFormat } from '../../util/DateUtil';
import { red } from '@mui/material/colors';

const EventoCard = ({ evento }) => {
    const listModel = [
        Model_1, Model_2, Model_3, Model_4, Model_5, Model_6, Model_7, Model_8
    ];

    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        {evento.descricao.substring(0, 1)}
                    </Avatar>
                }
                title={evento.descricao}
                subheader={dateFormat(evento.dataEvento)}
            />
            <CardMedia
                component='img'
                height='194'
                image={evento.urlBannerEvento != null ? evento.urlBannerEvento :
                    listModel[Math.floor(Math.random() * listModel.length)]
                }
            />
        </Card>
    );
}

export default EventoCard;