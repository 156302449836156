import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import GeneroTable from "./GeneroTable";
import GeneroForm from "./GeneroForm";
import { toast } from "react-toastify";
import { errorMessage } from "../util/ConfirmAlertUtil";
import { page, remove, saveGenero } from "./api-genero";
import { confirmAlert } from "react-confirm-alert";

const GeneroContainer = () => {
  const [listGeneros, setListGeneros] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [genero, setGenero] = useState(null);
  const [isLoadingRemove, setIsLoadingRemove] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [size, setSize] = useState(10);

  useEffect(() => {
    fetchData(0);
  }, []);

  const handleChangePage = (event, newPage) => {
    fetchData(newPage);
  };

  const fetchData = (newPage) => {
    setIsLoading(true);
    page(newPage).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListGeneros(data);
        }
      }
      setIsLoading(false);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
  };

  const handleDelete = (codigo) => {
    confirmAlert({
      title: "Alerta",
      message: "Deseja deletar este gênero?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            setIsLoadingRemove(true);
            remove(codigo).then((data) => {
              if (data.error) {
                errorMessage(data.error);
              } else {
                setListGeneros((categoriaElement) => ({
                  ...categoriaElement,
                  content: categoriaElement.content.filter(
                    (element) => element.codigo !== codigo
                  ),
                }));
                setGenero(null);
                toast.success("Registro excluído com Sucesso.");
              }
              setIsLoadingRemove(false);
            });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const handleSave = () => {
    if (genero.descricao === null || genero.descricao === "") {
      errorMessage("Campo Descrição não pode ser vazio.");
    }
    const eNovo = genero.eNovo;
    saveGenero(genero).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          if (eNovo) {
            setListGeneros((prevState) => ({
              ...prevState,
              content: [data, ...prevState.content],
            }));
          } else {
            setListGeneros((prevState) => ({
              ...prevState,
              content: prevState.content.map((content) => {
                if (content.codigo === data.codigo) {
                  return data;
                } else {
                  return content;
                }
              }),
            }));
          }
          setGenero(data);
          toast.success("Operação Realizada com Sucesso.");
        }
      }
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          {genero ? (
            <GeneroForm
              genero={genero}
              setGenero={setGenero}
              handleSave={handleSave}
            />
          ) : (
            <GeneroTable
              listGeneros={listGeneros}
              handleDelete={handleDelete}
              isLoadingRemove={isLoadingRemove}
              setGenero={setGenero}
              handleChangePage={handleChangePage}
              isLoading={isLoading}
              size={size}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default GeneroContainer;
