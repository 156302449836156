
const auth = {
    isAuthenticated() {
        if (typeof window == "undefined") {
            return false
        }

        const token = localStorage.getItem('token');
        if (token)
            return token
        else
            return false
    }
}

export default auth
