import { Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CoreografiaPagamentoTable from "./CoreografiaPagamentoTable";
import { errorMessage } from "../util/ConfirmAlertUtil";
import {
  consultarTransacao,
  criarTransacaoViaPix,
  findByCoreografiaCodigo,
} from "./api-coreografia-pagamento";
import CoreografiaTableSelectView from "../Coreografia/CoreografiaTableSelectView";
import CoreografiaProfile from "../Coreografia/CoreografiaProfile";
import { findByInstituicaoCodigo } from "../Coreografia/api-coreografia";
import { currencyRound } from "../../util/DoubleUtil";
import Button from "../UI/Button";
import Title from "../UI/Title";

const CoreografiaPagamentoContainer = () => {
  const [listCoreografiaPagamentos, setListCoreografiaPagamentos] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [listCoreografias, setListCoreografias] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [coreografia, setCoreografia] = useState(null);
  const [total, setTotal] = useState(0);
  const [totalComDesconto, setTotalComDesconto] = useState(0);
  const [isLoadingPixTransacao, setIsLoadingPixTransacao] = useState(false);

  useEffect(() => {
    fetchCoreografias(0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (coreografia) {
      fetchCoreografiaPagamento(0);
    }
    // eslint-disable-next-line
  }, [coreografia]);

  const fetchCoreografias = (newPage) => {
    setIsLoading(true);
    findByInstituicaoCodigo(newPage, size).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListCoreografias(data);
        }
      }
      setIsLoading(false);
    });
  };

  const fetchCoreografiaPagamento = (pageNumber) => {
    setIsLoading(true);
    setTotal(0);
    setTotalComDesconto(0);
    findByCoreografiaCodigo(coreografia.codigo, pageNumber, size).then(
      (data) => {
        if (data) {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setListCoreografiaPagamentos(data);
            data.content.forEach((element) => {
              setTotal((prevState) => prevState + element.valor);
              setTotalComDesconto((prevState) => prevState + element.valor - (element.valor * element.porcentagemDesconto / 100))
            }
            );
          }
        }
        setIsLoading(false);
      }
    );
  };

  const handleCoreografiaChangePage = (event, newPage) => {
    fetchCoreografias(newPage);
  };

  const handleCoreografiaPagamentoChangePage = (event, newPage) => {
    fetchCoreografiaPagamento(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
  };

  const handleTrocaCoreografia = () => {
    setCoreografia(null);
  };

  const handleCriarTransacaoPix = () => {
    setIsLoadingPixTransacao(true);
    criarTransacaoViaPix(coreografia.codigo).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setCoreografia((prevState) => ({
            ...prevState,
            pixTransacao: data,
          }));
        }
      }
      setIsLoadingPixTransacao(false);
    });
  };

  const handleConsultarTransacao = () => {
    consultarTransacao(coreografia.codigo).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setCoreografia((prevState) => ({
            ...prevState,
            pixTransacao: data,
          }));
        }
      }
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          {coreografia ? (
            <>
              <Grid container direction="column" spacing={2}>
                <Grid item sx={{ display: "flex" }}>
                  <CoreografiaProfile
                    coreografia={coreografia}
                    handleTrocaCoreografia={handleTrocaCoreografia}
                  />
                </Grid>

                <Grid item>
                  <CoreografiaPagamentoTable
                    listCoreografiaPagamentos={listCoreografiaPagamentos}
                    handleChangePage={handleCoreografiaPagamentoChangePage}
                    isLoading={isLoading}
                    size={size}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Grid>
                <Grid item>
                  <Typography>Total: R$ {currencyRound(total)}</Typography>
                </Grid>
                <Grid item>
                  <Typography>Total com Desconto: R$ {currencyRound(totalComDesconto)}</Typography>
                </Grid>
                {!coreografia.pixTransacao || coreografia.pixTransacao.quitado === null ||
                  coreografia.pixTransacao.quitado === false ? (
                  <>
                    <Grid item>
                      <Grid container spacing={3} direction="row">
                        <Grid item>
                          <Button
                            disabled={isLoadingPixTransacao}
                            onClick={handleCriarTransacaoPix}
                          >
                            Gerar QrCode Pix
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            disabled={isLoadingPixTransacao}
                            onClick={handleConsultarTransacao}
                          >
                            Consultar Transação
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    {coreografia.pixTransacao && (
                      <Grid item>
                        <Title>Pix</Title>
                        <Typography>
                          {coreografia.pixTransacao &&
                            coreografia.pixTransacao.qrCodeTexto
                            ? coreografia.pixTransacao.qrCodeTexto
                            : ""}
                        </Typography>
                        <img
                          alt=""
                          src={
                            coreografia.pixTransacao &&
                              coreografia.pixTransacao.qrCodeImagem
                              ? coreografia.pixTransacao.qrCodeImagem
                              : null
                          }
                        />
                      </Grid>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </>
          ) : (
            <>
              <CoreografiaTableSelectView
                listCoreografias={listCoreografias}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                size={size}
                handleCoreografiaChangePage={handleCoreografiaChangePage}
                setCoreografia={setCoreografia}
                isLoading={isLoading}
              />
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CoreografiaPagamentoContainer;
