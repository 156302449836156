import { Grid } from '@mui/material';
import React from 'react';
import Button from '../UI/Button';

const CoreografiaAgenciaEventoMenu = ({ setMenu, coreografiaKey, pagamentoKey, participanteKey, notaKey, notaPremiacaoKey }) => {
    const handleSelectMenu = (value) => {
        setMenu(value);
    }
    return (
        <Grid container direction='row' justifyContent='space-around'>
            <Grid item>
                <Button
                    onClick={() => handleSelectMenu(coreografiaKey)}
                >Coreografia</Button>
            </Grid>
            <Grid item>
                <Button
                    onClick={() => handleSelectMenu(participanteKey)}
                >Participante</Button>
            </Grid>
            <Grid item>
                <Button
                    onClick={() => handleSelectMenu(pagamentoKey)}
                >Pagamento</Button>
            </Grid>
            <Grid item>
                <Button
                    onClick={() => handleSelectMenu(notaKey)}
                >Nota Jurado</Button>
            </Grid>
            <Grid item>
                <Button
                    onClick={() => handleSelectMenu(notaPremiacaoKey)}
                >Nota Premiacao</Button>
            </Grid>
        </Grid>
    )
}

export default CoreografiaAgenciaEventoMenu;