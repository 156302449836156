import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import ButtonCancel from "../UI/ButtonCancel";
import ButtonSave from "../UI/ButtonSave";
import CancelIcon from "@mui/icons-material/Cancel";
import TextFieldDate from "../UI/TextFieldDate";
import {
  formaCobrancaText,
  POR_COREOGRAFIA,
  POR_PARTICIPANTE,
  POR_PARTICIPANTE_CATEGORIA,
} from "./FormaCobranca";

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const EventoForm = ({ evento, setEvento, handleSave, isLoadingAction }) => {
  const handleOnChange = (event) => {
    setEvento((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleDateOnChange = (dateSelected, name) => {
    setEvento((prevState) => ({
      ...prevState,
      [name]: dateSelected,
    }));
  };

  const handleOnChangeChecked = (event) => {
    setEvento((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleCancel = () => {
    setEvento(null);
  };

  const handleSelectChange = (event) => {
    setEvento((prevState) => ({
      ...prevState,
      formaCobranca: event.target.value,
    }));
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <IconButton onClick={handleCancel}>
          <CancelIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <FormGrid item xs={4}>
          <FormLabel htmlFor="descricao" required>
            Descricao
          </FormLabel>
          <OutlinedInput
            id="descricao"
            name="descricao"
            type="name"
            value={evento.descricao ? evento.descricao : ""}
            onChange={handleOnChange}
            placeholder="Descrição"
            required
          />
        </FormGrid>
      </Grid>
      <FormGrid item xs={3}>
        <FormLabel htmlFor="pais">País</FormLabel>
        <OutlinedInput
          id="pais"
          name="pais"
          type="name"
          value={evento.pais ? evento.pais : ""}
          onChange={handleOnChange}
          placeholder="País"
          required
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormLabel htmlFor="estadi">Estado</FormLabel>
        <OutlinedInput
          id="estado"
          name="estado"
          type="name"
          value={evento.estado ? evento.estado : ""}
          onChange={handleOnChange}
          placeholder="Estado"
          required
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormLabel htmlFor="cidade">Cidade</FormLabel>
        <OutlinedInput
          id="cidade"
          name="cidade"
          type="name"
          value={evento.cidade ? evento.cidade : ""}
          onChange={handleOnChange}
          placeholder="Cidade"
          required
        />
      </FormGrid>
      <FormGrid item xs={6}>
        <FormLabel htmlFor="local">Local</FormLabel>
        <OutlinedInput
          id="local"
          name="local"
          type="name"
          value={evento.local ? evento.local : ""}
          onChange={handleOnChange}
          placeholder="Local"
          required
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormLabel htmlFor="dataEvento" required>
          Data Início Evento
        </FormLabel>
        <TextFieldDate
          onChange={(dateSelected) =>
            handleDateOnChange(dateSelected, "dataEvento")
          }
          value={evento.dataEvento ? new Date(evento.dataEvento) : new Date()}
          name="dataEvento"
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormControlLabel
          control={
            <Checkbox
              name="inscricaoAberta"
              onChange={handleOnChangeChecked}
              value={evento.inscricaoAberta ? evento.inscricaoAberta : false}
            />
          }
          label="Inscrição Aberta"
        />
        <FormHelperText><span style={{ fontWeight: 'bold' }}>Atenção: </span>As instituições poderão cadastrar as coreografias apenas com esse campo selecionado.</FormHelperText>
      </FormGrid>
      <FormGrid item xs={3}>
        <FormLabel htmlFor="maximoDiretores">Máximo Diretores</FormLabel>
        <OutlinedInput
          id="maximoDiretores"
          name="maximoDiretores"
          type="number"
          value={evento.maximoDiretores ? evento.maximoDiretores : ""}
          onChange={handleOnChange}
          placeholder="Máximo Diretores"
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormLabel htmlFor="maximoAssistentes">Máximo Assistentes</FormLabel>
        <OutlinedInput
          id="maximoAssistentes"
          name="maximoAssistentes"
          type="number"
          value={evento.maximoAssistentes ? evento.maximoAssistentes : ""}
          onChange={handleOnChange}
          placeholder="Máximo Assistentes"
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormLabel htmlFor="maximoCoreografos">Máximo Coreografos</FormLabel>
        <OutlinedInput
          id="maximoCoreografos"
          name="maximoCoreografos"
          type="number"
          value={evento.maximoCoreografos ? evento.maximoCoreografos : ""}
          onChange={handleOnChange}
          placeholder="Máximo Coreografos"
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormLabel htmlFor="maximoCoreografiaPorGrupo">
          Máximo Coreografia por Grupo
        </FormLabel>
        <OutlinedInput
          id="maximoCoreografiaPorGrupo"
          name="maximoCoreografiaPorGrupo"
          type="number"
          value={
            evento.maximoCoreografiaPorGrupo
              ? evento.maximoCoreografiaPorGrupo
              : ""
          }
          onChange={handleOnChange}
          placeholder="Máximo Coreofrafia por Grupo"
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormLabel htmlFor="urlRegulamento">URL Regulamento</FormLabel>
        <OutlinedInput
          id="urlRegulamento"
          name="urlRegulamento"
          type="text"
          value={evento.urlRegulamento ? evento.urlRegulamento : ""}
          onChange={handleOnChange}
          placeholder="URL Regulamento"
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormLabel htmlFor="urlBannerEvento">URL Banner Evento</FormLabel>
        <OutlinedInput
          id="urlBannerEvento"
          type="text"
          name="urlBannerEvento"
          value={evento.urlBannerEvento ? evento.urlBannerEvento : ""}
          onChange={handleOnChange}
          placeholder="URL Banner Evento"
        />
      </FormGrid>

      <FormGrid item xs={3}>
        <FormControlLabel
          control={
            <Checkbox
              name="seletiva"
              onChange={handleOnChangeChecked}
              value={evento.seletiva ? evento.seletiva : false}
            />
          }
          label="Seletiva"
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormLabel htmlFor="taxaSeletiva">Taxa Seletiva</FormLabel>
        <OutlinedInput
          id="taxaSeletiva"
          name="taxaSeletiva"
          type="number"
          value={evento.taxaSeletiva ? evento.taxaSeletiva : ""}
          onChange={handleOnChange}
          placeholder="Taxa Seletiva"
        />
      </FormGrid>

      <FormGrid item xs={3}>
        <FormControlLabel
          control={
            <Checkbox
              name="exibirResultadoSeletiva"
              onChange={handleOnChangeChecked}
              value={
                evento.exibirResultadoSeletiva
                  ? evento.exibirResultadoSeletiva
                  : false
              }
            />
          }
          label="Exibir Resultado da Seletiva"
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormControlLabel
          control={
            <Checkbox
              name="exibirNotaAudioJuradoParaInstituicao"
              onChange={handleOnChangeChecked}
              value={
                evento.exibirNotaAudioJuradoParaInstituicao
                  ? evento.exibirNotaAudioJuradoParaInstituicao
                  : false
              }
            />
          }
          label="Exibir Nota e Áudio do Jurado para a Instituição"
        />
      </FormGrid>

      <FormGrid item xs={3}>
        <Box sx={{ minWidth: 120, display: "flex", mb: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="formaCobranca">Forma Cobrança</InputLabel>
            <Select
              labelId="formaCobranca"
              id="formaCobranca"
              value={
                evento && evento.formaCobranca ? evento.formaCobranca : null
              }
              label="Forma Cobrança"
              onChange={handleSelectChange}
            >
              <MenuItem value={POR_PARTICIPANTE}>
                {formaCobrancaText(POR_PARTICIPANTE)}
              </MenuItem>
              <MenuItem value={POR_PARTICIPANTE_CATEGORIA}>
                {formaCobrancaText(POR_PARTICIPANTE_CATEGORIA)}
              </MenuItem>
              <MenuItem value={POR_COREOGRAFIA}>
                {formaCobrancaText(POR_COREOGRAFIA)}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </FormGrid>

      <FormGrid item xs={3}>
        <FormControlLabel
          control={
            <Checkbox
              name="exigirFotoParticipante"
              onChange={handleOnChangeChecked}
              value={
                evento.exigirFotoParticipante
                  ? evento.exigirFotoParticipante
                  : false
              }
            />
          }
          label="Exigir Foto Participante"
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormControlLabel
          control={
            <Checkbox
              name="inscricaoAberta"
              onChange={handleOnChangeChecked}
              value={evento.inscricaoAberta ? evento.inscricaoAberta : false}
            />
          }
          label="Inscrição Aberta"
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormControlLabel
          control={
            <Checkbox
              name="permissaoDivulgacaoDataEvento"
              onChange={handleOnChangeChecked}
              value={
                evento.permissaoDivulgacaoDataEvento
                  ? evento.permissaoDivulgacaoDataEvento
                  : false
              }
            />
          }
          label="Permissão Divulgação Data do Evento"
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormControlLabel
          control={
            <Checkbox
              name="permissaoDivulgacaoOrdemApresentacao"
              onChange={handleOnChangeChecked}
              value={
                evento.permissaoDivulgacaoOrdemApresentacao
                  ? evento.permissaoDivulgacaoOrdemApresentacao
                  : false
              }
            />
          }
          label="Permissão Divulgação Ordem da Apresentação"
        />
      </FormGrid>

      <FormGrid item xs={3}>
        <FormLabel htmlFor="classificacao">Classificação</FormLabel>
        <OutlinedInput
          id="classificacao"
          name="classificacao"
          type="text"
          value={evento.classificacao ? evento.classificacao : ""}
          onChange={handleOnChange}
          placeholder="Classificação"
        />
      </FormGrid>

      <FormGrid item xs={3}>
        <FormControlLabel
          control={
            <Checkbox
              name="quesitos"
              onChange={handleOnChangeChecked}
              value={evento.quesitos ? evento.quesitos : false}
            />
          }
          label="Quesitos"
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormControlLabel
          control={
            <Checkbox
              name="permitirDancarinoPorMaisUmGrupo"
              onChange={handleOnChangeChecked}
              value={
                evento.permitirDancarinoPorMaisUmGrupo
                  ? evento.permitirDancarinoPorMaisUmGrupo
                  : false
              }
            />
          }
          label="Permitir dançarino por mais de um grupo"
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormControlLabel
          control={
            <Checkbox
              name="enderecoCompleto"
              onChange={handleOnChangeChecked}
              value={evento.enderecoCompleto ? evento.enderecoCompleto : false}
            />
          }
          label="Endereço Completo"
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormControlLabel
          control={
            <Checkbox
              name="emitirCertificadoParticipacao"
              onChange={handleOnChangeChecked}
              value={
                evento.emitirCertificadoParticipacao
                  ? evento.emitirCertificadoParticipacao
                  : false
              }
            />
          }
          label="Emitir Certificado de Participação"
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormControlLabel
          control={
            <Checkbox
              name="emitirCertificadoCursoSite"
              onChange={handleOnChangeChecked}
              value={
                evento.emitirCertificadoCursoSite
                  ? evento.emitirCertificadoCursoSite
                  : false
              }
            />
          }
          label="Emitir Certificado Curso Site"
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormControlLabel
          control={
            <Checkbox
              name="emitirCertificadoClassificacao"
              onChange={handleOnChangeChecked}
              value={
                evento.emitirCertificadoClassificacao
                  ? evento.emitirCertificadoClassificacao
                  : false
              }
            />
          }
          label="Emitir Certificado Classificação"
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormControlLabel
          control={
            <Checkbox
              name="cpfObrigatorio"
              onChange={handleOnChangeChecked}
              value={evento.cpfObrigatorio ? evento.cpfObrigatorio : false}
            />
          }
          label="CPF Obrigatório"
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormControlLabel
          control={
            <Checkbox
              name="urlArteCertificado"
              onChange={handleOnChangeChecked}
              value={
                evento.urlArteCertificado ? evento.urlArteCertificado : false
              }
            />
          }
          label="URL Arte Certificado"
        />
      </FormGrid>
      {evento.formaCobranca && evento.formaCobranca === POR_PARTICIPANTE && (
        <>
          <FormGrid item xs={3}>
            <FormLabel htmlFor="valorBailarinos">Valor Bailarinos</FormLabel>
            <OutlinedInput
              id="valorBailarinos"
              name="valorBailarinos"
              type="number"
              value={evento.valorBailarinos ? evento.valorBailarinos : ""}
              onChange={handleOnChange}
              placeholder="Valor Bailarinos"
            />
          </FormGrid>
        </>
      )}

      {evento.formaCobranca && evento.formaCobranca === POR_COREOGRAFIA && (
        <FormGrid item xs={3}>
          <FormLabel htmlFor="valorCoreografias">Valor Coreografias</FormLabel>
          <OutlinedInput
            id="valorCoreografias"
            name="valorCoreografias"
            type="number"
            value={evento.valorCoreografias ? evento.valorCoreografias : ""}
            onChange={handleOnChange}
            placeholder="Valor Coreografias"
          />
        </FormGrid>
      )}
      <FormGrid item xs={3}>
        <FormLabel htmlFor="valorDiretores">Valor Diretores</FormLabel>
        <OutlinedInput
          id="valorDiretores"
          name="valorDiretores"
          type="number"
          value={evento.valorDiretores ? evento.valorDiretores : ""}
          onChange={handleOnChange}
          placeholder="Valor Diretores"
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormLabel htmlFor="valorAssistentes">Valor Assistentes</FormLabel>
        <OutlinedInput
          id="valorAssistentes"
          name="valorAssistentes"
          type="number"
          value={evento.valorAssistentes ? evento.valorAssistentes : ""}
          onChange={handleOnChange}
          placeholder="Valor Assistentes"
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormLabel htmlFor="valorCoreografos">Valor Coreografos</FormLabel>
        <OutlinedInput
          id="valorCoreografos"
          name="valorCoreografos"
          type="number"
          value={evento.valorCoreografos ? evento.valorCoreografos : ""}
          onChange={handleOnChange}
          placeholder="Valor Coreografos"
        />
      </FormGrid>

      <FormGrid item xs={3}>
        <FormLabel htmlFor="taxaEcad">Taxa Ecad</FormLabel>
        <OutlinedInput
          id="taxaEcad"
          name="taxaEcad"
          type="number"
          value={evento.taxaEcad ? evento.taxaEcad : ""}
          onChange={handleOnChange}
          placeholder="Taxa Ecad"
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormLabel htmlFor="dataLimiteDesconto" >
          Data Limite Desconto
        </FormLabel>
        <TextFieldDate
          onChange={(dateSelected) =>
            handleDateOnChange(dateSelected, "dataLimiteDesconto")
          }
          value={evento.dataLimiteDesconto ? new Date(evento.dataLimiteDesconto) : ''}
          name="dataLimiteDesconto"
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormLabel htmlFor="porcentagemDesconto">Porcentagem Desconto</FormLabel>
        <OutlinedInput
          id="porcentagemDesconto"
          name="porcentagemDesconto"
          type="number"
          value={evento.porcentagemDesconto ? evento.porcentagemDesconto : ""}
          onChange={handleOnChange}
          placeholder="Porcentagem Desconto"
        />
      </FormGrid>
      <FormGrid item xs={12}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <ButtonCancel handleCancel={handleCancel} />
          </Grid>
          <Grid item>
            <ButtonSave
              isLoadingAction={isLoadingAction}
              handleSave={handleSave} />
          </Grid>
        </Grid>
      </FormGrid>
    </Grid>
  );
};

export default EventoForm;
