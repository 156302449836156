import { FormLabel, Grid, IconButton, OutlinedInput } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/system";
import ButtonCancel from "../../../UI/ButtonCancel";
import ButtonSave from "../../../UI/ButtonSave";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "../../../UI/Button";
import UsuarioPerfilJuradoFormSearchDialog from "../../../Usuario/UsuarioPerfil/UsuarioPerfilJuradoFormSearchDialog";

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const EventoDataJuradoForm = ({ eventoDataJurado, setEventoDataJurado, handleSave }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const handleCancel = () => {
    setEventoDataJurado(null);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const setUsuarioPerfil = (usuarioPerfil) => {
    setEventoDataJurado((prevState) => ({
      ...prevState,
      usuarioPerfil: usuarioPerfil
    }))
    setOpenDialog(false);
  }

  return (
    <Grid container spacing={3}>
      <UsuarioPerfilJuradoFormSearchDialog
        setUsuarioPerfil={setUsuarioPerfil}
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}

      />
      <Grid item xs={12}>
        <IconButton onClick={handleCancel}>
          <CancelIcon />
        </IconButton>
      </Grid>

      <Grid item xs={12}>
        <FormGrid item xs={12}>
          <FormLabel htmlFor="usuarioPerfil">
            Jurado
          </FormLabel>
          <OutlinedInput
            id="usuarioPerfil"
            name="usuarioPerfil"
            type="name"
            value={
              eventoDataJurado.usuarioPerfil ?
                eventoDataJurado.usuarioPerfil.usuario.nome + " - " +
                eventoDataJurado.usuarioPerfil.usuario.email :
                ""
            }
            placeholder="Jurado"
            readOnly={true}
          />
        </FormGrid>
      </Grid>
      <Grid item xs={12}>
        <Button onClick={() => setOpenDialog(true)}>Localizar Jurado</Button>
      </Grid>

      <FormGrid item xs={12}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <ButtonCancel handleCancel={handleCancel} />
          </Grid>
          <Grid item>
            <ButtonSave handleSave={handleSave} />
          </Grid>
        </Grid>
      </FormGrid>
    </Grid>
  );
};

export default EventoDataJuradoForm;
