import {
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import Button from "../UI/Button";
import Loading from "../UI/Loading";
import Title from "../UI/Title";
import { timeFormatTz } from "../../util/DateUtil";

const ApresentacaoTable = ({
  listApresentacoes,
  isLoading,
  apresentacaoIntervalo,
  setApresentacaoIntervalo,
  handleAdicionarIntervalo,
  handleEditarIntervalo,
  handleTrocarOrdem,
  handleRemover,
  isLoadingAction, 
  handleAdicionarCoreografia
}) => {
  const handleOnChange = (event) => {
    if (
      apresentacaoIntervalo &&
      apresentacaoIntervalo.apresentacaoReferencia &&
      apresentacaoIntervalo.apresentacaoReferencia.codigo === event.target.value
    ) {
      setApresentacaoIntervalo(null);
    } else {
      setApresentacaoIntervalo((prevState) => ({
        ...prevState,
        apresentacaoReferencia: listApresentacoes.find(
          (ap) => ap.codigo === event.target.value
        ),
      }));
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Title>Apresentação</Title>
        </Grid>

        <Grid item>
          <Grid container direction="row" spacing={3}>
            <Grid item>
              <Button isLoadingAction={isLoadingAction} onClick={handleAdicionarIntervalo}>
                Adicionar Intervalo
              </Button>
            </Grid>
            <Grid item>
              <Button isLoadingAction={isLoadingAction} onClick={handleAdicionarCoreografia}>
                Adicionar Coreografia
              </Button>
            </Grid>
            <Grid item>
              <Button
                isLoadingAction={isLoadingAction}
                onClick={handleEditarIntervalo}>Editar</Button>
            </Grid>
            <Grid item>
              <Button
                isLoadingAction={isLoadingAction}
                onClick={handleTrocarOrdem}>Trocar Ordem</Button>
            </Grid>
            <Grid item>
              <Button
                isLoadingAction={isLoadingAction}
                onClick={handleRemover}>Remover</Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Categoria</TableCell>
                <TableCell>Posição</TableCell>
                <TableCell>Duração</TableCell>
                <TableCell>Instituição</TableCell>
                <TableCell>Coreografia</TableCell>
                <TableCell>Nome Música</TableCell>
                <TableCell>Início</TableCell>
                <TableCell>Fim</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <Loading description="Carregando apresentações..." />
              ) : (
                <>
                  {listApresentacoes.length === 0 ? (
                    <Typography sx={{ py: 3 }}>
                      Nenhuma apresentação cadastrada
                    </Typography>
                  ) : (
                    <>
                      {listApresentacoes.map((a) => (
                        <TableRow>
                          <TableCell>
                            <Checkbox
                              value={a.codigo}
                              checked={
                                apresentacaoIntervalo &&
                                apresentacaoIntervalo.apresentacaoReferencia &&
                                apresentacaoIntervalo.apresentacaoReferencia
                                  .codigo === a.codigo
                              }
                              onChange={handleOnChange}
                            />
                          </TableCell>
                          <TableCell>
                            {a.coreografia &&
                              a.coreografia.eventoDataCategoria.categoria
                                .descricao
                              ? a.coreografia.eventoDataCategoria.categoria
                                .descricao
                              : "Intervalo"}
                          </TableCell>
                          <TableCell>{a.posicao ? a.posicao : ""}</TableCell>
                          <TableCell>{a.duracao ? a.duracao : ""}</TableCell>
                          <TableCell>
                            {a.coreografia &&
                              a.coreografia.instituicao.descricao
                              ? a.coreografia.instituicao.descricao
                              : ""}
                          </TableCell>
                          <TableCell>
                            {a.coreografia && a.coreografia.descricao
                              ? a.coreografia.descricao
                              : ""}
                          </TableCell>

                          <TableCell>
                            {a.coreografia && a.coreografia.nomeMusica
                              ? a.coreografia.nomeMusica
                              : ""}
                          </TableCell>
                          <TableCell>
                            {a.dataHoraInicio
                              ? timeFormatTz(a.dataHoraInicio)
                              : ""}
                          </TableCell>
                          <TableCell>
                            {a.dataHoraFim ? timeFormatTz(a.dataHoraFim) : ""}
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ApresentacaoTable;
